import React, { useEffect, useState, useMemo } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import MultiRangeSlider from 'components/MultiRangeSlider';
import { useSelector, useDispatch } from 'react-redux';
import {
	CatListService,
	productListService,
	productSearchService,
	loginService,
	productDtlService,
	/* createOrderService, */
	addToCartService,
	getRegionListService,
} from './MarketPlace.service.js';
import ReactPaginate from 'react-paginate';
import DataNotFound from 'components/DataNotFound';
import './MarketPlace.scss';
import './MarketPlace.responsive.scss';
import { Link, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import InputErrorMsg from 'components/InputErrorMsg';
import Alert from 'components/Alert';
import { toast } from 'react-toastify';
import allActions from 'redux/actions';
import noStar from 'assets/images/png-star.png';
import whenStar from 'assets/images/png-ystar.png';
import $ from 'jquery';

function MarketPlace() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [catList, setCatLst] = useState([]);
	const [productList, setProductLst] = useState([]);
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');

	const [activeCat, setActiveCat] = useState(0);
	const [activeSubCat, setActiveSubCat] = useState(0);
	const [activeProduct, setActiveProduct] = useState([]);
	const [activeMinPrice, setActiveMinPrice] = useState(1);
	const [activeMaxPrice, setActiveMaxPrice] = useState(1000);
	const [activeSortBy, setActiveSortBy] = useState('');
	const [activeRating, setActiveRating] = useState([]);

	const [region, setRegion] = useState([]);
	const [activeRegion, setActiveRegion] = useState([]);

	const [searchProduct, setSearchProduct] = useState([]);
	const [searchProductMeta, setSearchProductMeta] = useState({});

	const [productDtl, setProductDtl] = useState({});

	const [page, setPage] = useState(1);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const [showPassword, setShowPassword] = useState(false);
	const [quantity, setQuantity] = useState(1);

	const [setProductId] = useState(0);

	const [maxProdPrice, setMaxProdPrice] = useState(200);

	const lang = useSelector((state) => state.lang);
	let dir = 'ltr';
	dir = lang === 'ar' ? 'rtl' : 'ltr';

	const auth = useSelector((state) => state.auth);
	const cart = useSelector((state) => state.cart);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(allActions.loadingAction.showLoading());
		Promise.all([getCategoryList(), getRegionList()]).then(() => {
			dispatch(allActions.loadingAction.hideLoading());
		});

		document.getElementById('mobile_filter_btn').onclick = function () {
			document.querySelectorAll('.mobile-filter').forEach((el) => {
				el.classList.toggle('mobile_filter_open');
			});
			this.classList.toggle('open_filter');
		};
	}, []);

	// Same height
	useEffect(() => {
		var height_box = 0;
		$('.same_height_box').each(function () {
			if ($(this).outerHeight() >= height_box) {
				height_box = $(this).outerHeight();
			}
		});
		$('.same_height_box').css({
			'min-height': height_box,
		});
	}, [searchProduct]);

	const getCategoryList = async () => {
		let list = await CatListService();
		if (list.data.status === 200) {
			setCatLst(list.data.data.category);
		} else {
			SetAlertType('error');
			SetAlertMessage(list.data.error);
			window.scrollTo(0, 0);
		}
	};

	const getProductList = async () => {
		let list = await productListService(activeCat, activeSubCat);
		if (list.data.status === 200) {
			setProductLst(list.data.data.product);
			setMaxProdPrice(list.data.data.max_product_price);
			setActiveMaxPrice(list.data.data.max_product_price);
		} else {
			toast.error(list.data.error);
		}
	};
	const getRegionList = async () => {
		let list = await getRegionListService();
		if (list.data.status === 200) {
			setRegion(list.data.data.region);
		} else {
			toast.error(list.data.error);
		}
	};
	const handleClickCategory = async (id) => {
		setActiveCat(id);
		setActiveSubCat(0);
		setActiveProduct([]);
		document.querySelectorAll('input[type=checkbox]').forEach((el) => (el.checked = false));
	};
	const handleClickSubCategory = async (id) => {
		setActiveCat(0);
		setActiveSubCat(id);
		setActiveProduct([]);
		document.querySelectorAll('input[type=checkbox]').forEach((el) => (el.checked = false));
	};
	const handleChangeProductCheck = async (e) => {
		const { value, checked } = e.target;
		if (checked) {
			setActiveProduct([...activeProduct, value]);
		} else {
			setActiveProduct(activeProduct.filter((val) => val !== value));
		}
	};
	const handleChangeRegionCheck = async (e) => {
		const { value, checked } = e.target;
		if (checked) {
			setActiveRegion([...activeRegion, value]);
		} else {
			setActiveRegion(activeRegion.filter((val) => val !== value));
		}
	};
	const handleChangeRatingCheck = async (e) => {
		const { value, checked } = e.target;
		if (checked) {
			setActiveRating([...activeRating, value]);
		} else {
			setActiveRating(activeRating.filter((val) => val !== value));
		}
	};
	const handleSortBy = async (e) => {
		setActiveSortBy(e.target.value);
	};

	const getProductSearchList = async (data, page) => {
		dispatch(allActions.loadingAction.showLoading());
		let list = await productSearchService(data, page);
		if (list.data.status === 200) {
			await Promise.all([setSearchProduct(list.data.data.records), setSearchProductMeta(list.data.data.meta)]);

			setTimeout(() => {
				dispatch(allActions.loadingAction.hideLoading());
			}, 2000);
		} else {
			SetAlertType('error');
			SetAlertMessage(list.data.error);
			window.scrollTo(0, 0);
		}
	};

	useMemo(() => {
		setActiveCat(catList[0]?.id);
		setActiveSubCat(0);
	}, [catList]);

	useMemo(() => {
		getProductList();
	}, [activeCat, activeSubCat]);

	useMemo(async () => {
		const data = {
			product: activeProduct,
			cat: activeCat,
			sub_cat: activeSubCat,
			min_price: activeMinPrice,
			max_price: activeMaxPrice,
			sort_by: activeSortBy,
			location: activeRegion,
			rating: activeRating,
		};
		await getProductSearchList(data, page);
	}, [activeSortBy, activeCat, activeSubCat, activeProduct, activeMinPrice, activeMaxPrice, page, activeRegion, activeRating]);

	const onSubmit = async (data) => {
		let result = await loginService(data);
		if (result.data.status === 200) {
			localStorage.setItem('auth_token', result.data.data.token);
			window.location.reload();
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};

	const showProductDtl = async (id) => {
		dispatch(allActions.loadingAction.showLoading());
		setQuantity(1);
		let result = await productDtlService(id);
		if (result.data.status === 200) {
			setProductDtl(result.data.data.product);
			setProductId(result.data.data.product.id);
			setTimeout(() => {
				dispatch(allActions.loadingAction.hideLoading());
			}, 2000);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};

	/* const onSubmit2 = async (data) => {
		const formData = {
			quantity: data.quantity,
			vendor_product_id: productId,
		};
		let result = await createOrderService(formData);
		if (result.data.status === 200) {
			document.querySelector('.btn-close').click();
			toast.success(t('order_placed_successfully'));
		} else if (result.data.status === 401) {
			document.querySelector('.btn-close').click();
			toast.error(t('payout_order.unauthorized_action'));
		}
	}; */
	const goToCart = async () => {
		document.querySelector('.btn-close').click();
		navigate('/my-cart');
	};

	const addToCart = async (productId = null, qty) => {
		if (Number(qty) === 0 || qty === '') {
			toast.error(t('forum_page.invalid_quantity'));
			return false;
		}
		const formData = {
			product_id: productId,
			quantity: qty,
		};

		let result = await addToCartService(formData);
		if (result.data.status === 200) {
			document.querySelector('.btn-close').click();
			toast.success(`${t('forum_page.item_added_to_cart_successfully')} !`);
			dispatch(allActions.cartAction.addToCart([productId]));
		} else if (result.data.status === 401) {
			document.querySelector('.btn-close').click();
			toast.error(t('payout_order.unauthorized_action'));
		}
	};

	return (
		<>
			<Header />
			<>
				<section className="catagory-list-sec no-banner">
					<div className="container-fluid left-right-40">
						<div className="row">
							<div className="col-12">
								<div className="catagory-list-wrap">
									<ul className="d-flex">
										{catList.map((item, index) => {
											return (
												<li key={index}>
													<Link
														to="#"
														onClick={debounce(() => {
															handleClickCategory(item.id);
														}, 300)}
													>
														{item.name_lang[lang]}
													</Link>
													{item.get_sub_category.length > 0 && (
														<ul className="submenu">
															{item.get_sub_category.map((subCat, i) => {
																return (
																	<li key={i}>
																		<Link
																			to="#"
																			onClick={debounce(() => {
																				handleClickSubCategory(subCat.id);
																			}, 300)}
																		>
																			{subCat.name_lang[lang]}
																		</Link>
																	</li>
																);
															})}
														</ul>
													)}
												</li>
											);
										})}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="product-sec">
					<div className="container-fluid left-right-40">
						<div className="market-page-wrap d-flex flex-wrap">
							<div className="market-page-wrap-lft">
								<div className="mobile-filter-wrap top-sticky">
									<button type="button" id="mobile_filter_btn" className="mobile-filter-btn">
										<span className="first-i">
											<i className="fas fa-search"></i>
											{t('forum_page.filter')}
										</span>
										<span className="second-i">
											<i className="fas fa-times"></i>
											{t('forum_page.close')}
										</span>
									</button>
									<div className="mobile-filter mobile_filter_open">
										<div className="catagory-box reset-list">
											<ul className="d-flex justify-content-between">
												<li>
													<h4>{t('forum_page.reset_all_search')}</h4>
												</li>
												<li>
													<button
														type="button"
														className="reset-filter"
														onClick={() => {
															window.location.reload();
														}}
													>
														<i className="fas fa-sync"></i>
													</button>
												</li>
											</ul>
										</div>
										<div className="accordion filter-acrodian" id="accordionExample">
											<div className="accordion-item">
												<h2 className="accordion-header" id="headingOne">
													<button
														className="accordion-button"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseOne"
														aria-expanded="true"
														aria-controls="collapseOne"
													>
														{t('product')}
													</button>
												</h2>
												<div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
													<div className="accordion-body">
														<div className="catagory-box">
															<ul className="filter-chk">
																{productList.map((product, i) => {
																	return (
																		<li className="checkbox" key={i}>
																			<input type="checkbox" id={`chk${i + 1}`} value={product?.id} onChange={handleChangeProductCheck} />
																			<label htmlFor={`chk${i + 1}`}>{product?.name[lang]}</label>
																		</li>
																	);
																})}
															</ul>
														</div>
													</div>
												</div>
											</div>
											<div className="accordion-item">
												<h2 className="accordion-header" id="headingTwo">
													<button
														className="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseTwo"
														aria-expanded="false"
														aria-controls="collapseTwo"
													>
														{t('Price')}
													</button>
												</h2>
												<div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
													<div className="accordion-body">
														<div className="catagory-box">
															<MultiRangeSlider
																min={1}
																max={maxProdPrice}
																direction={dir}
																onChange={debounce(({ min, max }) => {
																	setActiveMinPrice(min);
																	setActiveMaxPrice(max);
																}, 300)}
															/>
														</div>
													</div>
												</div>
											</div>
											<div className="accordion-item">
												<h2 className="accordion-header" id="headingThree">
													<button
														className="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseThree"
														aria-expanded="false"
														aria-controls="collapseThree"
													>
														{t('event.location')}
													</button>
												</h2>
												<div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
													<div className="accordion-body">
														<div className="catagory-box">
															<ul className="filter-chk">
																{region.map((val, i) => {
																	return (
																		<li className="checkbox" key={i}>
																			<input type="checkbox" id={`region${i + 1}`} value={val?.id} onChange={handleChangeRegionCheck} />
																			<label htmlFor={`region${i + 1}`}>{val?.name}</label>
																		</li>
																	);
																})}
															</ul>
														</div>
													</div>
												</div>
											</div>
											<div className="accordion-item">
												<h2 className="accordion-header" id="headingFour">
													<button
														className="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseFour"
														aria-expanded="false"
														aria-controls="collapseFour"
													>
														{t('rating')}
													</button>
												</h2>
												<div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
													<div className="accordion-body">
														<div className="catagory-box">
															<ul className="filter-chk">
																<li className="checkbox">
																	<input type="checkbox" id="chk1r" value={5} onChange={handleChangeRatingCheck} />
																	<label htmlFor="chk1r">
																		<i className="fas fa-star" />
																		<i className="fas fa-star" />
																		<i className="fas fa-star" />
																		<i className="fas fa-star" />
																		<i className="fas fa-star" />
																	</label>
																</li>
																<li className="checkbox">
																	<input type="checkbox" id="chk2r" value={4} onChange={handleChangeRatingCheck} />
																	<label htmlFor="chk2r">
																		<i className="fas fa-star" />
																		<i className="fas fa-star" />
																		<i className="fas fa-star" />
																		<i className="fas fa-star" />
																		<i className="far fa-star" />
																	</label>
																</li>
																<li className="checkbox">
																	<input type="checkbox" id="chk3r" value={3} onChange={handleChangeRatingCheck} />
																	<label htmlFor="chk3r">
																		<i className="fas fa-star" />
																		<i className="fas fa-star" />
																		<i className="fas fa-star" />
																		<i className="far fa-star" />
																		<i className="far fa-star" />
																	</label>
																</li>
																<li className="checkbox">
																	<input type="checkbox" id="chk4r" value={2} onChange={handleChangeRatingCheck} />
																	<label htmlFor="chk4r">
																		<i className="fas fa-star" />
																		<i className="fas fa-star" />
																		<i className="far fa-star" />
																		<i className="far fa-star" />
																		<i className="far fa-star" />
																	</label>
																</li>
																<li className="checkbox">
																	<input type="checkbox" id="chk5r" value={1} onChange={handleChangeRatingCheck} />
																	<label htmlFor="chk5r">
																		<i className="fas fa-star" />
																		<i className="far fa-star" />
																		<i className="far fa-star" />
																		<i className="far fa-star" />
																		<i className="far fa-star" />
																	</label>
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="market-page-wrap-rgt">
								<div className="row">
									<div className="col-12">
										<div className="row justify-content-between align-items-center">
											<div className="col-auto">
												<div className="sort-select">
													<select className="form-control selectOption" onChange={handleSortBy}>
														<option value={''}>{t('sorted_by')}</option>
														<option value="price_asc">{t('price_low_to_high')}</option>
														<option value="price_desc">{t('price_high_to_low')}</option>
														<option value="newest">{t('newest_first')}</option>
													</select>
												</div>
											</div>
											<div className="col-auto d-flex">
												{searchProduct.length > 0 && (
													<p className="shoing-text">
														{t('showing')} {searchProductMeta?.recordFrom} - {searchProductMeta?.recordTo} {t('of')} {searchProductMeta?.totalRecords}{' '}
														{t('results')}
													</p>
												)}
											</div>
										</div>
									</div>
									{searchProduct.length > 0 ? (
										<>
											{searchProduct.map((prod, i) => {
												return (
													<div className="col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12" key={i}>
														<div className="product-box">
															<div className="product-img">
																<img className="img-block" src={prod?.media_path + '/' + prod?.image} alt={prod?.name?.[lang]} />

																<ul className="product-icon"></ul>
															</div>
															<div className="product-desc">
																<div className="same_height_box">
																	<ul className="product-catagories d-flex">
																		<li>{prod?.get_product?.get_category?.name_lang?.[lang]}</li>
																		{prod?.get_product?.get_sub_category?.name_lang && (
																			<li>{prod?.get_product?.get_sub_category?.name_lang?.[lang]}</li>
																		)}
																		<li>{prod?.get_product?.name?.[lang]}</li>
																	</ul>
																	<h4>{prod?.name?.[lang]}</h4>
																	<p>
																		<strong>{t('sold_by')} : </strong>
																		{prod?.get_vendor?.first_name + ' ' + prod?.get_vendor?.last_name}
																	</p>
																	<p>
																		<strong>{t('event.location')} : </strong>
																		{prod?.get_location?.name}
																	</p>
																	<ul className="new-ratting-wrap d-flex align-items-center">
																		<li>
																			<div className="new-ratting-box">
																				<span className="no-rating">
																					<img src={noStar} alt="star" />
																					<span
																						className="when-rating"
																						style={{
																							width: `${prod?.rating * 20}%`,
																						}}
																					>
																						<img src={whenStar} alt="with star" />
																					</span>
																				</span>
																			</div>
																		</li>
																		<li className="total-retting">{prod?.rating}/5.0</li>
																	</ul>
																	<h4>
																		MRU {prod?.price}/{prod?.get_measure_unit?.name?.[lang]}
																	</h4>
																</div>
																<div className="text-center">
																	{auth.id ? (
																		<Link
																			to="#"
																			onClick={() => {
																				showProductDtl(prod.id);
																			}}
																			className="buy-now-btn"
																			data-bs-toggle="modal"
																			data-bs-target="#staticBackdrop"
																		>
																			{t('buy_now')}
																		</Link>
																	) : (
																		<Link to="#" onClick={() => {}} className="buy-now-btn" data-bs-toggle="modal" data-bs-target="#loginModal">
																			{t('buy_now')}
																		</Link>
																	)}
																</div>
															</div>
														</div>
													</div>
												);
											})}
										</>
									) : (
										<>
											<DataNotFound text={t('no_product_found')} />
										</>
									)}
									{searchProduct.length > 0 && (
										<ReactPaginate
											previousLabel={t('previos')}
											nextLabel={t('next')}
											breakLabel={'...'}
											pageCount={searchProductMeta?.totalPages}
											marginPagesDisplayed={2}
											pageRangeDisplayed={3}
											onPageChange={(page) => setPage(page.selected + 1)}
											containerClassName={'pagination justify-content-center custom-paginate'}
											pageClassName={'page-item'}
											pageLinkClassName={'page-link'}
											previousClassName={'page-item'}
											previousLinkClassName={'page-link'}
											nextClassName={'page-item'}
											nextLinkClassName={'page-link'}
											breakClassName={'page-item'}
											breakLinkClassName={'page-link'}
											activeClassName={'active'}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</section>

				{/* Modal */}
				<div
					className="modal fade cmn-modal"
					id="staticBackdrop"
					data-bs-backdrop="static"
					data-bs-keyboard="false"
					tabIndex={-1}
					aria-labelledby="staticBackdropLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-xl modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="staticBackdropLabel">
									{t('product')}
								</h5>
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
									<i className="far fa-times-circle"></i>
								</button>
							</div>
							<div className="modal-body">
								<div className="row">
									<div className="col-lg-6 col-md-6 col-sm-12 col-12">
										<div className="product-modal-img d-flex align-items-center justify-content-center">
											<img className="img-block" src={productDtl?.image} alt={productDtl?.get_product?.name?.[lang]} />
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-12 col-12">
										<div className="product-modal-text">
											<h4>{productDtl?.name?.[lang]}</h4>
											<p>
												{t('sold_by')} : {productDtl?.get_vendor?.first_name + ' ' + productDtl?.get_vendor?.last_name}
											</p>
											<h4>
												MRU {productDtl?.price}/{productDtl?.get_measure_unit?.name?.[lang]}
											</h4>
											<p>
												{t('review.quality_available')} : {productDtl?.quality?.[lang]}
											</p>
											<p>
												{t('event.location')} : {productDtl?.get_location?.name}
											</p>
											<p>{t('description')}</p>
											<p className="sort-dsc mt-0 text-grey">{productDtl?.description?.[lang]}</p>
										</div>
										{auth?.role === 3 && (
											<>
												{!cart.includes(productDtl?.id) ? (
													<>
														<div className="product-modal-qty-wrap mt-2">
															<label className="front-lbl" htmlFor="">
																{t('quantity')}
															</label>
															<div className="d-flex">
																<div className="product-modal-qty">
																	<div className="admim-input-wrap mb-0">
																		<input
																			type="number"
																			className="admim-input-style form-control"
																			onChange={(e) => {
																				setQuantity(e.target.value);
																			}}
																			value={quantity}
																		/>
																	</div>
																</div>
																<div className="qty-type-wrap">
																	<span className="qty-type">{productDtl?.get_measure_unit?.name?.[lang]}</span>
																</div>
																<button
																	className="addt-crt-btn"
																	onClick={debounce(() => {
																		addToCart(productDtl.id, quantity);
																	}, 500)}
																>
																	{t('admin_user_type.add_To_Cart')} <i className="fas fa-cart-plus"></i>
																</button>
															</div>
														</div>
													</>
												) : (
													<button type="button" className="addt-crt-btn" onClick={goToCart}>
														{t('admin_user_type.go_to_cart')}
													</button>
												)}
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* Login Modal */}
				<div
					className="modal fade cmn-modal"
					id="loginModal"
					data-bs-backdrop="static"
					data-bs-keyboard="false"
					tabIndex={-1}
					aria-labelledby="loginModalLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="loginModalLabel">
									{t('login')}
								</h5>
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
									<i className="far fa-times-circle"></i>
								</button>
							</div>
							<div className="modal-body">
								<form onSubmit={handleSubmit(onSubmit)}>
									<p className="info-box">{t('please_login_to_buy_product')}</p>

									{alertType && <Alert type={alertType} message={alertMessage} />}
									<div className="admin-input-wrap mb-2">
										<label className="admin-lbl">{t('email')}</label>
										<input
											type="text"
											className="form-control admin-input-style"
											placeholder={t('enter_your_email')}
											{...register('email', {
												required: t('email_is_reqired'),
											})}
										/>
										{errors.email && <InputErrorMsg error={errors.email?.message} />}
									</div>
									<div className="admin-input-wrap">
										<label className="admin-lbl">{t('password')}</label>
										<div className="add-icon">
											<input
												type={showPassword ? 'text' : 'password'}
												className="form-control admin-input-style"
												placeholder={t('enter_your_password')}
												{...register('password', {
													required: t('password_is_reqired'),
												})}
											/>
											<span className="pass-icon">
												<i
													className={showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'}
													onClick={() => {
														setShowPassword(!showPassword);
													}}
												></i>
											</span>
										</div>
										{errors.password && <InputErrorMsg error={errors.password?.message} />}
									</div>
									<div className="logreg-btn-wrap">
										<button type="submit" className="logreg-btn">
											{t('login')}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</>
			<Footer />
		</>
	);
}

export default MarketPlace;
