import React, { useEffect, useState } from 'react';
import NoImage from 'assets/images/avatar.png';
import { useForm } from 'react-hook-form';
import InputErrorMsg from 'components/InputErrorMsg';
import Alert from 'components/Alert';
import { listCountry, listRole, addUser, listRegion } from './AddUser.service.js';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { useTranslation } from 'react-i18next';

function AddUser() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [countryList, SetCountryList] = useState([]);
	const [roleList, setRoleList] = useState([]);
	const [avatar, setAvatar] = useState('');
	const [regionList, SetRegionList] = useState([]);
	const { t } = useTranslation();

	useEffect(() => {
		getCountry();
		getRole();
		getRegion();
	}, []);

	const getCountry = async () => {
		let result = await listCountry();
		if (result.data.status === 200) {
			let country = result.data.data.country;
			let list = country.map((res, i) => {
				return { value: res.id, label: res.name };
			});
			SetCountryList(list);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};
	const getRole = async () => {
		let result = await listRole();
		if (result.data.status === 200) {
			let role = result.data.data.role;
			setRoleList(role);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};
	const getRegion = async () => {
		let result = await listRegion();
		if (result.data.status === 200) {
			let region = result.data.data.region;
			SetRegionList(region);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};

	const handleFile = async (e) => {
		let file = e.target.files[0];
		if (file.size > 200000) {
			SetAlertType('error');
			SetAlertMessage(t('about_content.image_size_less_200_kb'));
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setAvatar(img);
		}
	};
	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				resolve(baseURL);
			};
		});
	};
	const onSubmit = async (data) => {
		let submitData = { ...data, avatar };

		let result = await addUser(submitData);
		if (result.data.status === 200) {
			SetAlertType('success');
			SetAlertMessage(`${t('add_user_data.user_added_successful')} !`);
			reset();
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
		}
		window.scrollTo(0, 0);
	};

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('admin_user.users') },
					{ link: '#', name: t('admin_product_type.add') },
				]}
				title={t('admin_user.add_user')}
			/>
			<div className="my-profile-wrap">
				<div className="my-profile-box">
					{alertType && <Alert type={alertType} message={alertMessage} />}
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="profile-img-edit">
							<div className="profile-edit-btn">
								<input id="profileEdit" type="file" name="avatar" accept={t('add_user_data.png_jpg_jpeg')} onChange={handleFile} />
								<label htmlFor="profileEdit"></label>
							</div>
							<div className="profileimg-view">
								<img id="imgPrev" className="img-block" src={avatar ? avatar : NoImage} alt="Avatar" />
							</div>
						</div>
						<div className="row">
							<div className="col-lg-6 col-md-6 col-sm-12 col-12">
								<label className="admin-lbl">{t('admin_user.first_name')}</label>
								<div className="admim-input-wrap">
									<input
										type="text"
										className="form-control admim-input-style"
										placeholder={t('admin_user.first_name')}
										{...register('first_name', { required: t('add_user_data.first_name_is_required') })}
									/>
									{errors.first_name && <InputErrorMsg error={errors.first_name?.message} />}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-12">
								<label className="admin-lbl">{t('admin_user.last_name')}</label>
								<div className="admim-input-wrap">
									<input
										type="text"
										className="form-control admim-input-style"
										placeholder={t('admin_user.last_name')}
										{...register('last_name', { required: t('add_user_data.last_name_is_required') })}
									/>
									{errors.last_name && <InputErrorMsg error={errors.last_name?.message} />}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-12">
								<label className="admin-lbl">{t('admin_user.role')}</label>
								<div className="admim-select-wrap">
									<select className="form-control selectArrowBlk admim-select-style" {...register('role', { required: t('add_user_data.role_is_required') })}>
										<option value="">{`--${t('funding_details.select')}--`}</option>
										{roleList.map((role, i) => {
											return (
												<>
													<option key={i} value={role.id}>
														{role.name}
													</option>
												</>
											);
										})}
									</select>
									{errors.role && <InputErrorMsg error={errors.role?.message} />}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-12">
								<label className="admin-lbl">{t('admin_user.email')}</label>
								<div className="admim-input-wrap">
									<input
										type="text"
										className="form-control admim-input-style"
										placeholder={t('admin_user.email')}
										{...register('email', { required: t('add_user_data.email_is_reqired') })}
									/>
									{errors.email && <InputErrorMsg error={errors.email?.message} />}
								</div>
							</div>
							<div className="col-12">
								<label className="admin-lbl">{t('admin_user.company_name')}</label>
								<div className="admim-input-wrap">
									<input
										type="text"
										className="form-control admim-input-style"
										placeholder={t('admin_user.enter_your_company_name')}
										{...register('company_name')}
									/>
									{errors.company_name && <InputErrorMsg error={errors.company_name?.message} />}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-12">
								<label className="admin-lbl">{t('admin_user.company_VAT')}</label>
								<div className="admim-input-wrap">
									<input type="text" className="form-control admim-input-style" placeholder={t('admin_user.company_VAT_number')} {...register('company_vat')} />
									{errors.company_vat && <InputErrorMsg error={errors.company_vat?.message} />}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-12">
								<label className="admin-lbl">{t('admin_user.phone_number')}</label>
								<div className="admim-input-wrap">
									<input
										type="text"
										className="form-control admim-input-style"
										placeholder={t('admin_user.your_phone_number')}
										{...register('ph_no', { required: t('bank.phone_number_is_required') })}
									/>
									{errors.ph_no && <InputErrorMsg error={errors.ph_no?.message} />}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-12">
								<label className="admin-lbl">{t('admin_user.password')}</label>
								<div className="admim-input-wrap">
									<input
										type="password"
										className="form-control admim-input-style"
										placeholder={t('admin_user.password')}
										{...register('password', { required: t('add_user_data.password_is_reqired') })}
									/>
									{errors.password && <InputErrorMsg error={errors.password?.message} />}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-12">
								<label className="admin-lbl">{t('admin_user.confirm_passowrd')}</label>
								<div className="admim-input-wrap">
									<input
										type="password"
										className="form-control admim-input-style"
										placeholder={t('admin_user.confirm_passowrd')}
										{...register('password_confirmation', { required: t('add_user_data.confirm_password_is_required') })}
									/>
									{errors.password_confirmation && <InputErrorMsg error={errors.password_confirmation?.message} />}
								</div>
							</div>
							<div className="col-12">
								<label className="admin-lbl">{t('admin_user.address')}</label>
								<div className="admim-input-wrap">
									<input
										type="text"
										className="form-control admim-input-style"
										placeholder={t('admin_user.enter_your_address')}
										{...register('address', { required: t('add_user_data.address_is_required') })}
									/>
									{errors.address && <InputErrorMsg error={errors.address?.message} />}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-12">
								<label className="admin-lbl">{t('admin_user.zip_code')}</label>
								<div className="admim-input-wrap">
									<input type="text" className="form-control admim-input-style" placeholder={t('admin_user.zip_code')} {...register('zip_code')} />
									{errors.zip_code && <InputErrorMsg error={errors.zip_code?.message} />}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-12">
								<label className="admin-lbl">{t('admin_user.region')}</label>
								<div className="admim-select-wrap">
									<select
										className="form-control selectArrowBlk admim-select-style"
										{...register('region', { required: t('admin_dashboard_menu.region_is_required') })}
									>
										<option value="">{`--${t('funding_details.select')}--`}</option>
										{regionList?.map((value, index) => {
											return (
												<>
													<option value={value.id}>{value.name}</option>
												</>
											);
										})}
									</select>
									{errors.region && <InputErrorMsg error={errors.region?.message} />}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-12">
								<label className="admin-lbl">{t('admin_user.city')}</label>
								<div className="admim-input-wrap">
									<input
										type="text"
										className="form-control admim-input-style"
										placeholder={t('admin_user.city')}
										{...register('city', { required: t('add_user_data.city_is_required') })}
									/>
									{errors.city && <InputErrorMsg error={errors.city?.message} />}
								</div>
							</div>

							<div className="col-12">
								<div className="cmn-btn-wrap text-center">
									<button type="submit" className="cmn-btn">
										{t('admin_user.save')}
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}

export default AddUser;
