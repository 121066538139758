import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { Link } from 'react-router-dom';
import Alert from 'components/Alert';
import moment from 'moment';
import { listRaiseFundService, RaiseFundStatusChangeService, clearPaymentService } from './AdminFundRaiseList.service.js';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function AdminFundRaiseList() {
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [listFund, setListFund] = useState([]);
	const [listFundMeta, setListFundMeta] = useState({});
	const [page, setPage] = useState(1);
	const { t } = useTranslation();
	const lang = useSelector((state) => state.lang);

	const [receiptImg, setReceiptImg] = useState('');
	const [raiseFundId, setRaiseFundId] = useState(0);

	useEffect(() => {
		getFundRaiseList();
	}, []);

	const getFundRaiseList = async () => {
		let result = await listRaiseFundService(page);

		if (result.data.status === 200) {
			setListFund(result.data.data.records);
			setListFundMeta(result.data.data.meta);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
		}
		window.scrollTo(0, 0);
	};

	const changeStatus = async (id, status) => {
		let result = await RaiseFundStatusChangeService(id, status);

		if (result.data.status === 200) {
			SetAlertType('success');
			SetAlertMessage(`${t('fund_raise.status_successfully_changed')} !`);
			getFundRaiseList();
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
		}
		window.scrollTo(0, 0);
	};
	const handlePayment = async (order_id) => {
		setReceiptImg('');
		setRaiseFundId(order_id);
	};
	const handleFile = async (e) => {
		let file = e.target.files[0];
		if (file.size > 200000) {
			toast.error(t('about_content.image_size_less_200_kb'));
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setReceiptImg(img);
		}
	};
	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				resolve(baseURL);
			};
		});
	};

	const uploadPaymentReceipt = async () => {
		if (!receiptImg) {
			toast.error(t('payout_order.please_upload_the_reciept'));
			return false;
		}
		const raise_fund_id = raiseFundId;
		const image = receiptImg;
		const formData = {
			raise_fund_id,
			image,
		};
		let result = await clearPaymentService(formData);
		if (result.data.status === 200) {
			await getFundRaiseList();
			document.querySelector('.btn-close').click();
			toast.success(`{${t('payout_order.payement_cleared_successfully')}} !`);
		} else if (result.data.status === 401) {
			toast.error(`{${t('payout_order.unauthorized_action')}} !`);
		} else {
			toast.error(result.data.error);
		}
	};

	const columns = [
		{
			name: t('fund_raise.campaign_title'),
			selector: (row) => row.campaign_title?.[lang],
			sortable: true,
		},
		{
			name: t('fund_raise.created_date'),
			selector: (row) => moment(row.created_at).format('DD-MM-YYYY'),
			sortable: true,
		},
		{
			name: t('fund_raise.end_date'),
			selector: (row) => moment(row.campaign_end_date).format('DD-MM-YYYY'),
			sortable: true,
		},
		{
			name: t('fund_raise.budget_needed_mru'),
			selector: (row) => row.budget_needed,
			sortable: true,
		},
		{
			name: t('admin_user.status'),
			selector: (row) => {
				let status = '';
				if (row.status === 0) {
					status = <span class="badge bg-danger">{t('admin_user.inactive')}</span>;
				}
				if (row.status === 1) {
					status = <span class="badge bg-success">{t('admin_user.active')}</span>;
				}
				return status;
			},
			sortable: true,
		},
		{
			name: t('payout_order.payout_status'),
			selector: (row) => {
				if (row.payout_status === 0) {
					return <span className="badge bg-warning text-dark">{t('payout_order.not_requested')}</span>;
				}
				if (row.payout_status === 1) {
					return <span className="badge bg-primary">{t('payout_order.requested')}</span>;
				}
				if (row.payout_status === 2) {
					return <span className="badge bg-success">{t('payout_order.payment_cleared')}</span>;
				}
				if (row.payout_status === 3) {
					return <span className="badge bg-success">{t('payout_order.confirmed')}</span>;
				}
			},
			sortable: false,
		},
		{
			name: t('payout_order.action'),
			cell: (row) => (
				<div className="dropdown">
					<div className="actionList " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
						<svg
							style={{ cursor: 'pointer' }}
							xmlns="http://www.w3.org/2000/svg"
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth={2}
							strokeLinecap="round"
							strokeLinejoin="round"
							className="feather feather-sliders dropdown-toggle"
							data-toggle="dropdown"
							role="button"
							aria-expanded="false"
						>
							<line x1={4} y1={21} x2={4} y2={14} />
							<line x1={4} y1={10} x2={4} y2={3} />
							<line x1={12} y1={21} x2={12} y2={12} />
							<line x1={12} y1={8} x2={12} y2={3} />
							<line x1={20} y1={21} x2={20} y2={16} />
							<line x1={20} y1={12} x2={20} y2={3} />
							<line x1={1} y1={14} x2={7} y2={14} />
							<line x1={9} y1={8} x2={15} y2={8} />
							<line x1={17} y1={16} x2={23} y2={16} />
						</svg>
					</div>
					<div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
						{(row?.status === 0 || row?.days_left <= 0) && (
							<Link to={`/admin/fund/edit-fund/${btoa(row.id)}`} className="dropdown-item">
								{t('admin_user.edit')}
							</Link>
						)}
						{row.status === 0 ? (
							<Link
								to="#"
								className="dropdown-item"
								onClick={() => {
									changeStatus(row.id, 1);
								}}
							>
								{t('admin_user.active')}
							</Link>
						) : (
							<Link
								to="#"
								className="dropdown-item"
								onClick={() => {
									changeStatus(row.id, 0);
								}}
							>
								{t('fund_raise.deactive')}
							</Link>
						)}

						{row?.payout_status === 1 && (
							<Link
								to="#"
								className="dropdown-item"
								data-bs-toggle="modal"
								data-bs-target="#paymentModal"
								onClick={() => {
									handlePayment(row.id);
								}}
							>
								{t('payout_order.clear_payment')}
							</Link>
						)}
					</div>
				</div>
			),
		},
	];

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('fund_raise.fund_raise') },
					{ link: '#', name: t('admin_user.list') },
				]}
				title={t('fund_raise.list_fund_raise')}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}
			<div className="datatable-wrap">
				<DataTable
					columns={columns}
					data={listFund}
					striped
					pagination
					paginationServer
					paginationTotalRows={listFundMeta.totalRecords}
					paginationPerPage={15}
					paginationComponentOptions={{
						noRowsPerPage: true,
					}}
					onChangePage={(page) => setPage(page)}
				/>
			</div>

			<div
				className="modal fade cmn-modal"
				id="paymentModal"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="paymentModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="paymentModalLabel">
								{t('payout_order.payment_receipt')}
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
								<i className="far fa-times-circle"></i>
							</button>
						</div>
						<div className="modal-body">
							<div className="row">
								<div className="col-12">
									<div className="product-img-wrap">
										<div className="product-img-upload">
											<input type="file" id="receiptImg" onChange={handleFile} />
											<label htmlFor="receiptImg">
												{!receiptImg && (
													<div className="product-upload-text">
														<i className="fa fa-download"></i>
														<p>{`${t('payout_order.upload_receipt')}`}</p>
													</div>
												)}
												{receiptImg && (
													<div className="preview-img">
														<img src={receiptImg} className="img-block" alt="product" />
													</div>
												)}
											</label>
										</div>
									</div>
								</div>
								<div className="col-12 cmn-btn-tag-wrap">
									<button className="cmn-btn-tag" type="button" onClick={uploadPaymentReceipt}>
										{t('payout_order.submit')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default AdminFundRaiseList;
