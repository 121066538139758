import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { Link } from 'react-router-dom';
import Alert from 'components/Alert';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import { listRequestFormService, deleteRequestFormService, publishRequestFormService } from './ListRequestForm.service.js';
import './ListRequestForm.scss';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function ListRequestForm() {
	const [requestForm, setRequestForm] = useState([]);
	const [requestFormMeta, setRequestFormMeta] = useState({});
	const [page, setPage] = useState(1);
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const { t } = useTranslation();

	const auth = useSelector((state) => state.auth);
	useEffect(() => {
		getRequestFormList();
	}, [page]);

	const getRequestFormList = async () => {
		const list = await listRequestFormService(page);
		if (list.data.status === 200) {
			setRequestForm(list.data.data.records);
			setRequestFormMeta(list.data.data.meta);
		} else {
			SetAlertType('error');
			SetAlertMessage(list.data.error);
			window.scrollTo(0, 0);
		}
	};
	const deleteProd = async (id) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="custom-confirmation-box">
						<h1>{t('admin_user.are_you_sure')}</h1>
						<p>{t('admin_user.you_want_to_delete_this')}</p>
						<button onClick={onClose} className="no-btn">
							{t('admin_user.no')}
						</button>
						<button
							className="yes-btn"
							onClick={async () => {
								let result = await deleteRequestFormService(id);
								if (result.data.status === 200) {
									getRequestFormList();
									toast.success(t('admin_user.event_deleted_successfully'));
								} else {
									toast.error(result.data.error);
								}
								onClose();
							}}
						>
							{t('admin_user.yes_delete_it')}
						</button>
					</div>
				);
			},
		});
	};
	const publishReport = async (id) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="custom-confirmation-box">
						<h1>{t('admin_user.are_you_sure')}</h1>
						<p>{t('request_form.you_want_to_publish_this')}</p>
						<button onClick={onClose} className="no-btn">
							{t('admin_user.no')}
						</button>
						<button
							className="yes-btn"
							onClick={async () => {
								let result = await publishRequestFormService(id);
								if (result.data.status === 200) {
									getRequestFormList();
									toast.success(t('request_form.request_form_publish_successfully'));
								} else {
									toast.error(result.data.error);
								}
								onClose();
							}}
						>
							{`${t('request_form.yes_publish_it')} !`}
						</button>
					</div>
				);
			},
		});
	};
	const columns = [
		{
			name: t('request_form.request_type'),
			selector: (row) => row?.get_request_type?.name?.en,
			sortable: true,
		},
		{
			name: t('request_form.subject'),
			selector: (row) => row.subject?.en,
			sortable: true,
		},

		{
			name: t('about_content.description'),
			selector: (row) => row.description?.en,
			sortable: false,
		},
		{
			name: t('request_form.attached_file'),
			cell: (row) =>
				row?.attach_file ? (
					<div className="cmn-a-tag-wrap">
						<a className="cmn-a-tag" href={row?.media_path + '/' + row?.attach_file} download>
							<i className="fas fa-file-download"></i>
						</a>
					</div>
				) : (
					''
				),
			sortable: false,
		},
		{
			name: t('admin_category.created_at'),
			selector: (row) => moment(row.created_at).format('DD-MM-YYYY'),
			sortable: true,
		},
		{
			name: t('admin_request_product.created_by'),
			selector: (row) => row?.get_created_by?.full_name,
			sortable: true,
		},
		{
			name: t('request_form.is_published'),
			selector: (row) => (row?.is_publish === 1 ? t('admin_user.yes') : t('admin_user.no')),
			sortable: true,
		},
		{
			name: t('admin_user.action'),
			cell: (row) => (
				<div className="dropdown">
					<div className="actionList " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
						<svg
							style={{ cursor: 'pointer' }}
							xmlns="http://www.w3.org/2000/svg"
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth={2}
							strokeLinecap="round"
							strokeLinejoin="round"
							className="feather feather-sliders dropdown-toggle"
							data-toggle="dropdown"
							role="button"
							aria-expanded="false"
						>
							<line x1={4} y1={21} x2={4} y2={14} />
							<line x1={4} y1={10} x2={4} y2={3} />
							<line x1={12} y1={21} x2={12} y2={12} />
							<line x1={12} y1={8} x2={12} y2={3} />
							<line x1={20} y1={21} x2={20} y2={16} />
							<line x1={20} y1={12} x2={20} y2={3} />
							<line x1={1} y1={14} x2={7} y2={14} />
							<line x1={9} y1={8} x2={15} y2={8} />
							<line x1={17} y1={16} x2={23} y2={16} />
						</svg>
					</div>
					<div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
						<Link to={`/admin/request-form/edit/${btoa(row.id)}`} className="dropdown-item">
							{t('admin_request_product.edit')}
						</Link>
						<Link
							to="#"
							className="dropdown-item"
							onClick={() => {
								deleteProd(row.id);
							}}
						>
							{t('admin_request_product.delete')}
						</Link>
						{auth?.role === 1 && (
							<>
								<Link
									to="#"
									className="dropdown-item"
									onClick={() => {
										publishReport(row.id);
									}}
								>
									{t('request_form.publish_report')}
								</Link>
							</>
						)}
					</div>
				</div>
			),
		},
	];

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('request_form.request_form') },
					{ link: '#', name: t('admin_user.list') },
				]}
				title={t('request_form.request_form')}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}

			<div className="row mb-3 justify-content-between">
				<div className="col-lg-6">
					<div className="cmn-a-tag-wrap mb-3">
						<Link className="cmn-a-tag" to={`/admin/request-form/add`}>
							{t('request_form.add_request_form')}
						</Link>
					</div>
				</div>
			</div>
			<div className="datatable-wrap">
				<DataTable
					columns={columns}
					data={requestForm}
					striped
					pagination
					paginationServer
					paginationTotalRows={requestFormMeta.totalRecords}
					paginationPerPage={15}
					paginationComponentOptions={{
						noRowsPerPage: true,
					}}
					onChangePage={(page) => setPage(page)}
				/>
			</div>
		</>
	);
}

export default ListRequestForm;
