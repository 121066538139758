import React, { useEffect, useState } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import about_image from 'assets/images/about-content-img.jpg';
import about_bg from 'assets/images/about-btm.jpeg';
import partner1 from 'assets/images/partner/partner-logo.png';
import partner2 from 'assets/images/partner/partner-logo1.jpeg';
import partner3 from 'assets/images/partner/partner-logo2.png';

import OwlCarousel from 'react-owl-carousel';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarouselRtl from 'react-owl-carousel-rtl';
import './About.scss';
import './About.responsive.scss';
import allActions from 'redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import forum_bg from 'assets/images/forum.jpg';
import { useForm } from 'react-hook-form';
import InputErrorMsg from 'components/InputErrorMsg';
import { contactUsService, getContentService } from './About.service.js';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Partner from 'components/Partner';

function About() {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();
	const [content, setContent] = useState({});

	useEffect(() => {
		getContent();
	}, []);

	const lang = useSelector((state) => state.lang);

	var options = {};
	if (lang === 'ar') {
		options = {
			rtl: true,
			autoplayTimeout: 5000,
			smartSpeed: 2000,
			animateOut: 'fadeOut',
			loop: true,
			margin: 20,
			stagePadding: 15,
			nav: true,
			dots: false,
			navElement: 'div',
			navText: ["<i class='fas fa-arrow-left'></i>", "<i class='fas fa-arrow-right'></i>"],
			responsive: {
				0: {
					items: 1,
				},
				600: {
					items: 1,
				},
				1000: {
					items: 2,
				},
			},
		};
	} else {
		options = {
			autoplayTimeout: 5000,
			smartSpeed: 2000,
			animateOut: 'fadeOut',
			loop: true,
			margin: 20,
			stagePadding: 15,
			nav: true,
			dots: false,
			navElement: 'div',
			navText: ["<i class='fas fa-arrow-left'></i>", "<i class='fas fa-arrow-right'></i>"],
			responsive: {
				0: {
					items: 1,
				},
				600: {
					items: 1,
				},
				1000: {
					items: 2,
				},
			},
		};
	}

	const getContent = async () => {
		dispatch(allActions.loadingAction.showLoading());
		const result = await getContentService();
		if (result.data.status === 200) {
			setContent(result.data.data.content.content);
		} else {
			toast.error(result.data.error);
		}
		dispatch(allActions.loadingAction.hideLoading());
	};

	const onSubmit = async (data) => {
		dispatch(allActions.loadingAction.showLoading());
		let result = await contactUsService(data);
		dispatch(allActions.loadingAction.hideLoading());
		if (result.data.status === 200) {
			reset();
			toast.success(`${t('not_found_page.thank_you_for_your_message')}`);
		} else {
			toast.error(result.data.error);
		}
	};
	return (
		<>
			<Header />
			<div>
				<section className="inner-banner-sec d-flex align-items-center justify-content-center" style={{ background: `url(${forum_bg}) no-repeat` }}>
					<div className="inner-banner-head">
						<h3>{t('about_page.about_us')}</h3>
					</div>
				</section>
				<section className="about-content-sec">
					<div className="container-fluid left-right-40">
						<div className="row">
							<div className="col-lg-6 col-md-12 col-sm-12 col-12">
								<div className="about-text-pic">
									<img className="img-block" src={content?.about_img ?? about_image} alt="about" />
								</div>
							</div>
							<div className="col-lg-6 col-md-12 col-sm-12 col-12">
								<div className="about-content-text">
									<h3 dangerouslySetInnerHTML={{ __html: content?.title?.[lang] ?? 'OUR MISSION' }}></h3>
									<div
										dangerouslySetInnerHTML={{
											__html:
												content?.description?.[lang] ??
												`<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>`,
										}}
									></div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<div>
					<Partner />
					<section className="aboutus-btm-sec" style={{ background: `url(${about_bg}) no-repeat` }}>
						<div className="container-fluid left-right-40">
							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="sec-heading-two">
										<h3>{t('about_page.our_service')}</h3>
										<span
											dangerouslySetInnerHTML={{
												__html: content?.our_service?.[lang] ?? (
													<p>
														Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
														Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.{' '}
													</p>
												),
											}}
										/>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="get-in-touch">
										<div className="sec-heading-two">
											<h3 className="text-center">{t('about_page.get_in_touch')}</h3>
										</div>

										<form onSubmit={handleSubmit(onSubmit)}>
											<div className="row">
												<div className="col-lg-6 col-md-6 col-sm-12 col-12">
													<div className="input-wrap">
														<input
															type="text"
															className="form-control input-underline"
															placeholder={t('your_name')}
															{...register('name', { required: 'Name is required' })}
														/>
														{errors.name && <InputErrorMsg error={errors.name?.message} />}
													</div>
												</div>
												<div className="col-lg-6 col-md-6 col-sm-12 col-12">
													<div className="input-wrap">
														<input
															type="text"
															className="form-control input-underline"
															placeholder={t('phone_no')}
															{...register('ph_no', { required: 'Phone Number is required' })}
														/>
														{errors.ph_no && <InputErrorMsg error={errors.ph_no?.message} />}
													</div>
												</div>
												<div className="col-lg-6 col-md-6 col-sm-12 col-12">
													<div className="input-wrap">
														<input
															type="text"
															className="form-control input-underline"
															placeholder={t('email_address')}
															{...register('email', { required: 'Email is required' })}
														/>
														{errors.email && <InputErrorMsg error={errors.email?.message} />}
													</div>
												</div>
												<div className="col-lg-6 col-md-6 col-sm-12 col-12">
													<div className="input-wrap">
														<input
															type="text"
															className="form-control input-underline"
															placeholder={t('subject')}
															{...register('subject', { required: 'Subject is required' })}
														/>
														{errors.subject && <InputErrorMsg error={errors.subject?.message} />}
													</div>
												</div>
												<div className="col-12">
													<div className="txtare-wrap">
														<textarea
															type="text"
															className="form-control txtare-underline"
															placeholder={t('description')}
															defaultValue={''}
															{...register('message', { required: 'Description is required' })}
														/>
														{errors.message && <InputErrorMsg error={errors.message?.message} />}
													</div>
												</div>
												<div className="send-msg-btn-wrap">
													<button type="submit" className="white-btn">
														{t('about_page.submit')}
													</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>

			<Footer />
		</>
	);
}

export default About;
