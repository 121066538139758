import React, { useEffect, useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import InputErrorMsg from 'components/InputErrorMsg';
import Select from 'react-select';
import Breadcrumb from 'components/Admin/Breadcrumb';
import Alert from 'components/Alert';
import './AddFundRaise.scss';
import { raiseFundService, listRegion, fundRaiseCatListService, checkUserBankDetailsService } from './AddFundRaise.service.js';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

function AddFundRaise() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();

	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [uploadImage, setUploadImage] = useState([]);
	const [uploadDocument, setUploadDocument] = useState([]);
	const [regionList, setRegionList] = useState([]);
	const [category, setCategory] = useState([]);
	const { t } = useTranslation();
	const [teamMemberImg, setTeamImg] = useState([]);
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'team',
	});
	const [reRender, setReRender] = useState(0);
	const lang = useSelector((state) => state.lang);
	useEffect(() => {
		// horizontal scroll
		const scrollContainer = document.querySelector('.file-scroll');

		scrollContainer.addEventListener('wheel', (evt) => {
			evt.preventDefault();
			scrollContainer.scrollLeft += evt.deltaY;
		});
		checkUserBankDetailsHas();
		getRegion();
		getCategoryList();
	}, [lang]);

	const unique = (value, index, self) => {
		return self.indexOf(value) === index;
	};
	const handleFile = async (e) => {
		let files = Array.from(e.target.files);
		Promise.all(
			files.map((file) => {
				return getBase64(file);
			}),
		).then(
			(images) => {
				let datas = [...images, ...uploadImage];
				let uniquedata = datas.filter(unique);
				setUploadImage(uniquedata);
			},
			(error) => {
				console.error(error);
			},
		);
	};

	function deleteFile(e) {
		const img = uploadImage.filter((item, index) => index !== e);
		setUploadImage(img);
	}
	const checkUserBankDetailsHas = async () => {
		let result = await checkUserBankDetailsService();
		if (result.data.status === 200) {
			let bankDetails = result.data.data.bankDetails;
			if (bankDetails === undefined || bankDetails === null) {
				toast.error('Please add Bank Details');
				window.location.href = '/admin/bank-details';
			}
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};

	const getRegion = async () => {
		let result = await listRegion();
		if (result.data.status === 200) {
			let region = result.data.data.region;
			setRegionList(region);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};

	const handleTeamImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 200000) {
			toast.error(t('about_content.image_size_less_200_kb'));
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			teamMemberImg[index] = img;
			setTeamImg([...teamMemberImg]);
			setReRender(!reRender);
		}
	};
	const removeTeamItem = async (index) => {
		remove(index);
		setTeamImg(teamMemberImg.filter((val, i) => i !== index));
	};

	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				resolve(baseURL);
			};
		});
	};

	const onSubmit = async (data) => {
		const team = data.team.map((val, i) => ({ ...val, img: teamMemberImg[i] }));
		const newData = { ...data, team };
		let formData = new FormData();

		formData.append('campaign_title', data.campaign_title);
		formData.append('Campaign_description', data.Campaign_description);
		formData.append('Campaign_short_description', data.Campaign_short_description);
		formData.append('category', data.category?.value);
		formData.append('campaign_end_date', data.campaign_end_date);
		formData.append('region', data.region);
		formData.append('city', data.city);
		formData.append('address', data.address);
		formData.append('budget_needed', data.budget_needed);
		formData.append('image', JSON.stringify(uploadImage));
		formData.append('teams', JSON.stringify(newData.team));

		for (let i = 0; i < uploadDocument.length; i++) {
			formData.append(`documents[${i}]`, uploadDocument[i]);
		}

		let result = await raiseFundService(formData);

		if (result.data.status === 200) {
			SetAlertType('success');
			SetAlertMessage(`${t('fund_raise.fund_raised_successfully')}`);
			reset();
			setUploadImage([]);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
		}
		window.scrollTo(0, 0);
	};

	const handleDocument = async (e) => {
		let f = Array.from(e.target.files);
		setUploadDocument(f);
	};

	const getCategoryList = async () => {
		let result = await fundRaiseCatListService();

		if (result.data.status === 200) {
			let cat = result.data.data.category.map((res, i) => {
				return {
					value: res.id,
					label: res?.name?.[lang],
				};
			});
			setCategory(cat);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
		}
		window.scrollTo(0, 0);
	};

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('fund_raise.fund_raise') },
					{ link: '#', name: t('admin_product_type.add') },
				]}
				title={t('fund_raise.fund_raise')}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}
			<div className="admin-white-box">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row g-2">
						<div className="col-12">
							<label className="admin-lbl">{t('fund_raise.campaign_title_en')}</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control admim-input-style"
									placeholder={t('fund_raise.campaign_title_en')}
									{...register('campaign_title', {
										required: t('fund_raise.campaign_title_en_is_required'),
									})}
								/>
								{errors.campaign_title && <InputErrorMsg error={errors.campaign_title?.message} />}
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 col-12">
							<label className="admin-lbl">{t('fund_raise.campaign_description_en')}</label>
							<div className="admim-input-wrap">
								<textarea
									className="form-control resize-none"
									placeholder={t('fund_raise.campaign_description_en')}
									rows="5"
									{...register('Campaign_short_description', {
										required: t('fund_raise.campaign_description_en_is_required'),
									})}
								></textarea>
								{errors.Campaign_short_description && <InputErrorMsg error={errors.Campaign_short_description?.message} />}
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 col-12">
							<label className="admin-lbl">{t('admin_user_type.project_startup_description')}</label>
							<div className="admim-input-wrap">
								<textarea
									className="form-control resize-none"
									placeholder={t('admin_user_type.project_startup_description')}
									rows="5"
									{...register('Campaign_description', {
										required: t('admin_user_type.project_startup_description_is_required'),
									})}
								></textarea>
								{errors.Campaign_description && <InputErrorMsg error={errors.Campaign_description?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('admin_dashboard_menu.category')}</label>
							<div className="admim-select-wrap">
								<Controller
									name="category"
									control={control}
									render={({ field }) => <Select {...field} options={category} />}
									rules={{
										required: t('admin_dashboard_menu.category_is_required'),
									}}
								/>
								{errors.category && <InputErrorMsg error={errors.category?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('fund_raise.campaign_end_date')}</label>
							<div className="admim-input-wrap">
								<input
									type="date"
									className="form-control admim-input-style"
									placeholder={t('fund_raise.campaign_end_date')}
									{...register('campaign_end_date', {
										required: t('fund_raise.campaign_end_date_is_required'),
									})}
								/>
								{errors.campaign_end_date && <InputErrorMsg error={errors.campaign_end_date?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-12">
							<label className="admin-lbl">{t('admin_user.region')}</label>
							<div className="admim-select-wrap">
								<select
									className="form-control selectArrowBlk admim-select-style"
									{...register('region', {
										required: t('admin_dashboard_menu.region_is_required'),
									})}
								>
									<option value="">{`${t('funding_details.select')}`}</option>
									{regionList?.map((value, index) => {
										return (
											<option key={index} value={value.id}>
												{value.name}
											</option>
										);
									})}
								</select>
								{errors.region && <InputErrorMsg error={errors.region?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('admin_user.city')}</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control admim-input-style"
									placeholder={t('admin_user.city')}
									{...register('city', {
										required: t('fund_raise.city_is_required'),
									})}
								/>
								{errors.city && <InputErrorMsg error={errors.city?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('admin_user.address')}</label>
							<div className="admim-input-wrap">
								<input type="text" className="form-control admim-input-style" placeholder={t('admin_user.address')} />
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('fund_raise.budget_needed_mru')}</label>
							<div className="admim-input-wrap">
								<input
									type="number"
									className="form-control admim-input-style"
									placeholder={t('fund_raise.budget_needed')}
									{...register('budget_needed', {
										required: t('fund_raise.budget_needed_is_required'),
									})}
								/>
								{errors.budget_needed && <InputErrorMsg error={errors.budget_needed?.message} />}
							</div>
						</div>
						<div className="col-lg-8 col-md-8 col-sm-6 col-12">
							<div className="overfolow-hid">
								<label className="admin-lbl">{t('fund_raise.upload_images')}</label>
								<div className="row g-2">
									<div className="col-auto image-upload-wrap">
										<div className="admim-input-wrap">
											<input type="file" id="image_upload" className="form-control" multiple onChange={handleFile} />
											<label htmlFor="image_upload" className="image-upload-lbl">
												<i className="fas fa-upload"></i>
												{t('fund_raise.upload_images')}
												{uploadImage.length > 0 && (
													<span className="doc-count">
														{uploadImage.length} {t('fund_raise.images_selected')}
													</span>
												)}
											</label>
										</div>
									</div>
									<div className="col d-flex file-scroll">
										{uploadImage.map((res, i) => {
											return (
												<>
													<div className="fund-img-wrap">
														<img className="img-block" src={res} alt="" />
														<button type="button" className="image-del" onClick={() => deleteFile(i)}>
															-
														</button>
													</div>
												</>
											);
										})}
									</div>
								</div>
							</div>
						</div>

						<div className="col-lg-4 col-md-4 col-sm-12 col-12">
							<label className="admin-lbl">{t('fund_raise.upload_documents')}</label>
							<div className="row g-2">
								<div className="col-auto image-upload-wrap">
									<div className="admim-input-wrap">
										<input type="file" id="document_upload" className="form-control" multiple onChange={handleDocument} />
										<label htmlFor="document_upload" className="image-upload-lbl">
											<i className="fas fa-upload"></i>
											{t('fund_raise.upload_documents')}{' '}
											{uploadDocument.length > 0 && (
												<span className="doc-count">
													{uploadDocument.length} {t('fund_raise.files_selected')}
												</span>
											)}
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="fund-raiser-member-add">
						<div className="fund-raiser-member-head">{t('fund_catagory.add_team')}</div>
						<div className="row g-2 mt-0">
							{fields.map((item, index) => (
								<div className="col-lg-4 col-md-6 col-sm-12 col-12 member-add-wrap" key={index}>
									<div className="member-add-box">
										<span className="member-add-box-btn-wrap">
											<button type="button" className="member-add-box-btn" onClick={() => removeTeamItem(index)}>
												<i class="fas fa-times"></i>
											</button>
										</span>
										<div className="member-add-box-head">{t('fund_catagory.member_details')}</div>
										<div className="product-img-wrap">
											<div className="product-img-upload">
												<input type="file" id={`team_member_img${index}`} onChange={(e) => handleTeamImg(e, index)} />
												<label htmlFor={`team_member_img${index}`}>
													{!teamMemberImg?.[index] && (
														<div className="product-upload-text">
															<i className="fa fa-download"></i>
															<p>{`${t('admin_product_type.upload_image')} ...`}</p>
														</div>
													)}
													{teamMemberImg?.[index] && (
														<div className="preview-img">
															<img src={teamMemberImg?.[index]} className="img-block" alt="product" />
														</div>
													)}
												</label>
											</div>
										</div>
										<div className="admim-input-wrap">
											<label className="admin-lbl">{t('fund_catagory.member_name')}</label>
											<input
												type="text"
												className="form-control admim-input-style"
												placeholder={t('fund_catagory.enter_member_name')}
												{...register(`team[${index}].name`)}
											/>
										</div>
										<div className="admim-input-wrap">
											<label className="admin-lbl">{t('admin_user_type.member_description')}</label>
											<textarea
												className="form-control resize-none"
												placeholder={t('admin_user_type.member_description')}
												rows="5"
												{...register(`team[${index}].member_description`)}
											></textarea>
										</div>
									</div>
								</div>
							))}
							<div className="col-12 cmn-btn-tag-wrap mt-3">
								<button type="button" className="cmn-btn-tag black-bg" onClick={() => append({ name: '', img: '', member_description: '' })}>
									{t('fund_catagory.add_team')}
								</button>
							</div>
						</div>
					</div>
					<div className="row g-2">
						<div className="col-12 cmn-btn-tag-wrap mt-3">
							<button type="submit" className="cmn-btn-tag">
								{t('funding_details.submit')}
							</button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}

export default AddFundRaise;
