import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import InputErrorMsg from 'components/InputErrorMsg';
import Breadcrumb from 'components/Admin/Breadcrumb';
import Alert from 'components/Alert';
import { toast } from 'react-toastify';
import { editEventService, eventDetailsService, listEventType } from './EditEvent.service.js';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function EditEvent() {
	const params = useParams();
	const id = atob(params.id);
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		resetField,
	} = useForm();
	const [eventDetails, setEventDetails] = useState({});
	const [eventImg, setEventImg] = useState('');
	const [eventTypeList, SetEventTypeList] = useState([]);
	const { t } = useTranslation();
	const auth = useSelector((state) => state.auth);
	useEffect(() => {
		getEventDetails();
		getEventType();
	}, []);

	const getEventType = async () => {
		let result = await listEventType();
		if (result.data.status === 200) {
			let event_types = result.data.data.event_types;
			SetEventTypeList(event_types);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};

	useEffect(() => {
		setDefaultValueForm();
	}, [eventDetails]);

	const onSubmit = async (data) => {
		let formData = {
			title: data.title,
			title_ar: data.title_ar,
			title_fr: data.title_fr,
			event_type: data.event_type,
			date: data.date,
			time: data.time,
			location: data.location,
			description: data.description,
			description_fr: data.description_fr,
			description_ar: data.description_ar,
			organizer_name: data.organizer_name,
			organizer_phone_no: data.organizer_phone_no,
			organizer_email: data.organizer_email,
			status: data.status,
			event_image: eventImg,
		};
		const result = await editEventService(id, formData);
		if (result.data.status === 200) {
			toast.success(`${t('event.event_added_successfully')}`);
		} else {
			toast.error(result.data.error);
		}
	};

	const getEventDetails = async () => {
		const result = await eventDetailsService(id);
		if (result.data.status === 200) {
			setEventDetails(result.data.data.event);
		} else {
			toast.error(result.data.error);
		}
	};

	const setDefaultValueForm = async () => {
		let defaultValues = {};
		defaultValues.title = eventDetails?.title?.en;
		defaultValues.event_type = eventDetails?.event_type;
		defaultValues.date = eventDetails?.event_date;
		defaultValues.time = eventDetails?.event_time;
		defaultValues.location = eventDetails?.location;
		defaultValues.description = eventDetails?.description?.en;
		defaultValues.organizer_name = eventDetails?.organizer_name;
		defaultValues.organizer_phone_no = eventDetails?.organizer_phone_no;
		defaultValues.organizer_email = eventDetails?.organizer_email;
		defaultValues.status = eventDetails?.status;
		reset({ ...defaultValues });
		await setEventImg(eventDetails?.image);
	};
	const handleFile = async (e) => {
		let file = e.target.files[0];
		if (file.size > 200000) {
			SetAlertType('error');
			SetAlertMessage(t('about_content.image_size_less_200_kb'));
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setEventImg(img);
		}
	};
	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				resolve(baseURL);
			};
		});
	};

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('event.event') },
					{ link: '#', name: t('admin_product_type.add') },
				]}
				title={t('event.add_event')}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}

			<div className="admin-white-box">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						<div className="col-12">
							<div className="product-img-wrap">
								<div className="product-img-upload">
									<input type="file" id="event_image" onChange={handleFile} />
									<label htmlFor="event_image">
										{!eventImg && (
											<div className="product-upload-text">
												<i className="fa fa-download"></i>
												<p>{`${t('admin_product_type.upload_image')}...`}</p>
											</div>
										)}
										{eventImg && (
											<div className="preview-img">
												<img src={eventImg} className="img-block" alt="product" />
											</div>
										)}
									</label>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('about_content.title')}</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control admim-input-style"
									placeholder={t('about_content.title')}
									{...register('title', {
										required: t('about_content.title_is_required'),
									})}
								/>
								{errors.title && <InputErrorMsg error={errors.title?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('event.event_type')}</label>
							<select
								className="form-select"
								{...register('event_type', {
									required: t('event.event_type_is_required'),
								})}
							>
								<option value="">{t('event.select_event_type')}</option>
								{eventTypeList?.map((value, index) => {
									return (
										<option key={index} value={value.id}>
											{value.name?.en}
										</option>
									);
								})}
							</select>
							{errors.event_type && <InputErrorMsg error={errors.event_type?.message} />}
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('event.date')}</label>
							<div className="admim-input-wrap">
								<input
									type="date"
									className="form-control admim-input-style"
									placeholder={t('event.date')}
									{...register('date', {
										required: t('event.date_is_required'),
									})}
								/>
								{errors.date && <InputErrorMsg error={errors.date?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('event.time')}</label>
							<div className="admim-input-wrap">
								<input
									type="time"
									className="form-control admim-input-style"
									placeholder={t('event.time')}
									{...register('time', {
										required: t('event.time_is_required'),
									})}
								/>
								{errors.time && <InputErrorMsg error={errors.time?.message} />}
							</div>
						</div>

						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('event.location')}</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control admim-input-style"
									placeholder={t('event.location')}
									{...register('location', {
										required: t('event.location_is_required'),
									})}
								/>
								{errors.location && <InputErrorMsg error={errors.location?.message} />}
							</div>
						</div>

						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('event.organizer_name')}</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control admim-input-style"
									placeholder={t('event.organizer_name')}
									{...register('organizer_name', {
										required: t('event.organizer_name_is_required'),
									})}
								/>
								{errors.organizer_name && <InputErrorMsg error={errors.organizer_name?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('event.organizer_phone_no')}</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control admim-input-style"
									placeholder={t('event.organizer_phone_no')}
									{...register('organizer_phone_no', {
										required: t('event.organizer_phone_no_is_required'),
									})}
								/>
								{errors.organizer_phone_no && <InputErrorMsg error={errors.organizer_phone_no?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('event.organizer_email')}</label>
							<div className="admim-input-wrap">
								<input type="text" className="form-control admim-input-style" placeholder={t('event.organizer_email')} {...register('organizer_email')} />
								{errors.organizer_email && <InputErrorMsg error={errors.organizer_email?.message} />}
							</div>
						</div>
						<div className="col-12">
							<label className="admin-lbl">{`${t('about_content.description')} (en)`}</label>
							<div className="admim-input-wrap">
								<textarea
									className="form-control resize-none"
									placeholder={`${t('about_content.description')} (en)`}
									rows="5"
									{...register('description', {
										required: t('about_content.description_en_is_required'),
									})}
								></textarea>
								{errors.description && <InputErrorMsg error={errors.description?.message} />}
							</div>
						</div>
						{auth.role === 1 && (
							<div className="col-lg-4 col-md-4 col-sm-6 col-12">
								<label className="admin-lbl">{t('admin_user.status')}</label>
								<select className="form-select" {...register('status')}>
									<option value="0">{t('admin_user.inactive')}</option>
									<option value="1">{t('admin_user.active')}</option>
								</select>
								{errors.event_type && <InputErrorMsg error={errors.event_type?.message} />}
							</div>
						)}
						<div className="col-12 cmn-btn-tag-wrap mt-3">
							<button className="cmn-btn-tag">{t('payout_order.submit')}</button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}

export default EditEvent;
