import React, { useEffect, useState, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import InputErrorMsg from 'components/InputErrorMsg';
import Select from 'react-select';
import Breadcrumb from 'components/Admin/Breadcrumb';
import Alert from 'components/Alert';
import { useParams } from 'react-router-dom';
import { ProductList, RegionList, ProductEdit, Productdetail, MeasureUnitListService } from './EditVendorProduct.service.js';
import { useTranslation } from 'react-i18next';

function EditVendorProduct() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const [prodImg, setProdImg] = useState('');
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [productList, setProductList] = useState([]);
	const [regionList, setRegionList] = useState([]);
	const [productDtl, setProductDtl] = useState({});
	const [measureList, setMeasureList] = useState([]);
	const { t } = useTranslation();

	const params = useParams();
	const id = atob(params.id);

	useEffect(() => {
		getProductList();
		getRegionList();
		getProdDtl();
		getMeasureList();
	}, []);

	const getProductList = async () => {
		let list = await ProductList();
		if (list.data.status === 200) {
			let product = list.data.data.product.map((res, i) => {
				return { value: res.id, label: res.name?.en };
			});
			setProductList(product);
		} else {
			SetAlertType('error');
			SetAlertMessage(list.data.error);
			window.scrollTo(0, 0);
		}
	};
	const getProdDtl = async () => {
		let result = await Productdetail(id);

		if (result.data.status === 200) {
			let product = result.data.data.product;
			setProductDtl(product);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};

	const getRegionList = async () => {
		let list = await RegionList();
		if (list.data.status === 200) {
			let region = list.data.data.region.map((res, i) => {
				return { value: res.id, label: res.name };
			});
			setRegionList(region);
		} else {
			SetAlertType('error');
			SetAlertMessage(list.data.error);
			window.scrollTo(0, 0);
		}
	};
	const getMeasureList = async () => {
		let list = await MeasureUnitListService();
		if (list.data.status === 200) {
			let measure_unit = list.data.data.measure_unit.map((res, i) => {
				return { value: res.id, label: res.name?.en };
			});
			setMeasureList(measure_unit);
		} else {
			SetAlertType('error');
			SetAlertMessage(list.data.error);
			window.scrollTo(0, 0);
		}
	};

	const setDefaultValueForm = async () => {
		let defaultValues = {};
		defaultValues.name = productDtl?.name?.en;
		defaultValues.name_fr = productDtl?.name?.fr;
		defaultValues.name_ar = productDtl?.name?.ar;
		defaultValues.price = productDtl?.price;
		defaultValues.product = { value: productDtl?.get_product?.id, label: productDtl?.get_product?.name?.en };
		defaultValues.location = { value: productDtl?.get_location?.id, label: productDtl?.get_location?.name };

		defaultValues.description = productDtl?.description?.en;
		defaultValues.description_fr = productDtl?.description?.fr;
		defaultValues.description_ar = productDtl?.description?.ar;
		defaultValues.measure_unit = { label: productDtl?.get_measure_unit?.name?.en, value: productDtl?.get_measure_unit?.id };
		defaultValues.quality = productDtl?.quality?.en;
		defaultValues.quality_fr = productDtl?.quality?.fr;
		defaultValues.quality_ar = productDtl?.quality?.ar;
		defaultValues.quantity = productDtl?.qty;
		defaultValues.city = productDtl?.city;

		await reset({ ...defaultValues });
		await setProdImg(productDtl?.media_path + '/' + productDtl?.image);
	};
	useMemo(() => {
		setDefaultValueForm();
	}, [productDtl]);

	const handleFile = async (e) => {
		let file = e.target.files[0];
		if (file.size > 200000) {
			SetAlertType('error');
			SetAlertMessage(t('about_content.image_size_less_200_kb'));
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setProdImg(img);
		}
	};
	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				baseURL = reader.result;
				resolve(baseURL);
			};
		});
	};

	const onSubmit = async (data) => {
		let formData = {
			name: data.name,
			name_fr: data.name_fr,
			name_ar: data.name_ar,
			product: data.product.value,
			price: data.price,
			quantity: data.quantity,
			location: data.location.value,
			description: data.description,
			description_fr: data.description_fr,
			description_ar: data.description_ar,
			measure_unit: data.measure_unit['value'],
			quality: data.quality,
			quality_fr: data.quality_fr,
			quality_ar: data.quality_ar,
			product_image: prodImg,
			city: data.city,
		};
		let result = await ProductEdit(id, formData);
		if (result.data.status === 200) {
			SetAlertType('success');
			SetAlertMessage(`${t('vendor.product_updated_successfully')} !`);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
		}
		window.scrollTo(0, 0);
	};
	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('admin_dashboard_menu.product') },
					{ link: '#', name: t('admin_product_type.edit') },
				]}
				title={t('admin_dashboard_menu.product')}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}
			<div className="admin-white-box">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						<div className="col-12">
							<div className="product-img-wrap">
								<div className="product-img-upload">
									<input type="file" id="product_image" onChange={handleFile} />
									<label htmlFor="product_image">
										{!prodImg && (
											<div className="product-upload-text">
												<i className="fa fa-download"></i>
												<p>{`${t('fund_raise.upload_images')}...`}</p>
											</div>
										)}
										{prodImg && (
											<div className="preview-img">
												<img src={prodImg} className="img-block" alt="product" />
											</div>
										)}
									</label>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('admin_product_type.product')}</label>
							<div className="admim-select-wrap">
								<Controller
									name="product"
									control={control}
									render={({ field }) => <Select {...field} options={productList} />}
									rules={{ required: t('vendor_product.product_is_required') }}
								/>
								{errors.product && <InputErrorMsg error={errors.product?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('admin_profile.name')}</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control admim-input-style"
									placeholder={t('admin_profile.name')}
									{...register('name', { required: t('home_content_page.name_is_required') })}
								/>
								{errors.name && <InputErrorMsg error={errors.name?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('admin_product_type.name_fr')}</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control admim-input-style"
									placeholder={t('admin_product_type.name_fr')}
									{...register('name_fr', { required: t('vendor.name_fr_is_required') })}
								/>
								{errors.name_fr && <InputErrorMsg error={errors.name_fr?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('admin_product_type.name_ar')}</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control admim-input-style"
									placeholder={t('admin_product_type.name_ar')}
									{...register('name_ar', { required: t('vendor.name_ar_is_required') })}
								/>
								{errors.name_ar && <InputErrorMsg error={errors.name_ar?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('payout_order.Price')}</label>
							<div className="admim-input-wrap">
								<input
									type="number"
									className="form-control admim-input-style"
									placeholder={t('payout_order.Price')}
									{...register('price', { required: t('vendor_product.price_is_required') })}
								/>
								{errors.price && <InputErrorMsg error={errors.price?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('payout_order.quantity')}</label>
							<div className="admim-input-wrap">
								<input
									type="number"
									className="form-control admim-input-style"
									placeholder={t('payout_order.quantity')}
									{...register('quantity', { required: t('vendor_product.quantity_is_required') })}
								/>
								{errors.quantity && <InputErrorMsg error={errors.quantity?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('about_content.description')}</label>
							<div className="admim-textare-wrap">
								<textarea
									rows="2"
									className="form-control admim-textare-style"
									placeholder={t('about_content.description')}
									{...register('description')}
								></textarea>
								{errors.description && <InputErrorMsg error={errors.description?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('about_content.Description_fr')}</label>
							<div className="admim-textare-wrap">
								<textarea
									rows="2"
									className="form-control admim-textare-style"
									placeholder={t('about_content.Description_fr')}
									{...register('description_fr')}
								></textarea>
								{errors.description_fr && <InputErrorMsg error={errors.description_fr?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('about_content.Description_ar')}</label>
							<div className="admim-textare-wrap">
								<textarea
									rows="2"
									className="form-control admim-textare-style"
									placeholder={t('about_content.Description_ar')}
									{...register('description_ar')}
								></textarea>
								{errors.description_ar && <InputErrorMsg error={errors.description_ar?.message} />}
							</div>
						</div>

						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('vendor_product.quality')}</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control admim-input-style"
									placeholder={t('vendor_product.example_a_grade_b_grade_etc')}
									{...register('quality', { required: t('vendor_product.quality_is_required') })}
								/>
								{errors.quality && <InputErrorMsg error={errors.quality?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('vendor_product.quality_fr')}</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control admim-input-style"
									placeholder={t('vendor_product.example_a_grade_b_grade_etc')}
									{...register('quality_fr', { required: t('vendor_product.quality_fr_is_required') })}
								/>
								{errors.quality_fr && <InputErrorMsg error={errors.quality_fr?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('vendor_product.quality_ar')}</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control admim-input-style"
									placeholder={t('vendor_product.example_a_grade_b_grade_etc')}
									{...register('quality_ar', { required: t('vendor_product.quality_ar_is_required') })}
								/>
								{errors.quality_ar && <InputErrorMsg error={errors.quality_ar?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('vendor_product.measure_unit')}</label>
							<div className="admim-input-wrap">
								<Controller
									name="measure_unit"
									control={control}
									render={({ field }) => <Select {...field} options={measureList} />}
									rules={{ required: t('vendor_product.measure_unit_is_required') }}
								/>
								{errors.measure_unit && <InputErrorMsg error={errors.measure_unit?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('admin_user.region')}</label>
							<div className="admim-select-wrap">
								<Controller
									name="location"
									control={control}
									render={({ field }) => <Select {...field} options={regionList} />}
									rules={{ required: t('admin_dashboard_menu.region_is_required') }}
								/>
								{errors.location && <InputErrorMsg error={errors.location?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('admin_user.city')}</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control admim-input-style"
									placeholder={t('admin_user.city')}
									{...register('city', { required: t('fund_raise.city_is_required') })}
								/>
								{errors.city && <InputErrorMsg error={errors.city?.message} />}
							</div>
						</div>
						<div className="col-12">
							<div className="cmn-btn-tag-wrap">
								<button type="submit" className="cmn-btn-tag">
									{t('admin_user.save')}
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}

export default EditVendorProduct;
