import React, { useEffect, useState } from 'react';
import './DataObservatory.scss';
import './DataObservatory.responsive.scss';
import Header from 'components/Header';
import Footer from 'components/Footer';
import about_bg from 'assets/images/about-btm.jpeg';
import data_rgt from 'assets/images/data-rgt.jpeg';
import allActions from 'redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import OwlCarousel from 'react-owl-carousel';
import OwlCarouselRtl from 'react-owl-carousel-rtl';
import moment from 'moment';
import DataNotFound from 'components/DataNotFound';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import ModalEventRegister from 'components/EventRegister/EventRegister';
import { EqualHeight, EqualHeightElement } from 'react-equal-height';

import { getContentService, getEventService, getPublishedReportService } from './DataObservatory.service.js';

function DataObservatory() {
	const [content, setContent] = useState({});
	const [Eventcontent, setEventContent] = useState([]);
	const [publishedReport, setPublishReportContent] = useState([]);
	const [publishedReportMeta, setPublishReportMeta] = useState([]);
	const dispatch = useDispatch();
	const lang = useSelector((state) => state.lang);
	const { t } = useTranslation();
	const [page, setPage] = useState(1);
	const [openModals, setOpenModals] = useState({});
	const [event_id, setEventId] = useState('');

	useEffect(() => {
		getContent();
		getEventContent();
		getPublishedReport();
	}, []);

	const getContent = async () => {
		dispatch(allActions.loadingAction.showLoading());
		const result = await getContentService();
		if (result.data.status === 200) {
			setContent(result.data.data.content.content);
		} else {
			toast.error(result.data.error);
		}
		dispatch(allActions.loadingAction.hideLoading());
	};
	const getEventContent = async () => {
		dispatch(allActions.loadingAction.showLoading());
		const result = await getEventService();
		if (result.data.status === 200) {
			setEventContent(result.data.data.content);
		} else {
			toast.error(result.data.error);
		}
		dispatch(allActions.loadingAction.hideLoading());
	};
	const getPublishedReport = async () => {
		dispatch(allActions.loadingAction.showLoading());
		const result = await getPublishedReportService();
		if (result.data.status === 200) {
			setPublishReportContent(result.data.data.records);
			setPublishReportMeta(result.data.data.meta);
		} else {
			toast.error(result.data.error);
		}
		dispatch(allActions.loadingAction.hideLoading());
	};

	const openModal = (eventId) => {
		setEventId(eventId);
		setOpenModals((prevOpenModals) => ({
			...prevOpenModals,
			[eventId]: true,
		}));
	};

	const closeModal = (eventId) => {
		setEventId(eventId);
		setOpenModals((prevOpenModals) => ({
			...prevOpenModals,
			[eventId]: false,
		}));
	};
	// Event slider
	var options = {};
	if (lang === 'ar') {
		options = {
			rtl: true,
			autoplayTimeout: 5000,
			smartSpeed: 2000,
			autoplay: true,
			loop: true,
			margin: 20,
			stagePadding: 10,
			nav: true,
			dots: false,
			navElement: 'div',
			navText: ["<i class='fas fa-arrow-right'></i>", "<i class='fas fa-arrow-left'></i>"],
			responsive: {
				0: {
					items: 1,
				},
				600: {
					items: 1,
				},
				1000: {
					items: 1,
				},
			},
		};
	} else {
		options = {};
		options = {
			autoplayTimeout: 5000,
			smartSpeed: 2000,
			autoplay: true,
			loop: true,
			margin: 20,
			stagePadding: 10,
			nav: true,
			dots: false,
			navElement: 'div',
			navText: ["<i class='fas fa-arrow-left'></i>", "<i class='fas fa-arrow-right'></i>"],
			responsive: {
				0: {
					items: 1,
				},
				600: {
					items: 1,
				},
				1000: {
					items: 1,
				},
			},
		};
	}
	return (
		<>
			<Header />
			<section className="no-banner dataobservatory-sec-wrap">
				<div className="container-fluid left-right-40">
					<div className="row">
						<div className="col-lg-8 col-md-7 col-sm-12 col-12">
							<div className="row justify-content-between mb-3">
								<div className="col-auto"></div>
								<div className="col-auto">
									<ul className="data-social d-flex">
										<li>
											<a href="javascript:void(0)" className="fcbk">
												<i className="fab fa-facebook-f" />
											</a>
										</li>
										<li>
											<a href="javascript:void(0)" className="ytbe">
												<i className="fab fa-youtube" />
											</a>
										</li>
										<li>
											<a href="javascript:void(0)" className="twtr">
												<i className="fab fa-twitter" />
											</a>
										</li>
										<li>
											<a href="javascript:void(0)" className="lkdn">
												<i className="fab fa-linkedin" />
											</a>
										</li>
									</ul>
								</div>
							</div>
							{content?.leftPanel?.length > 0 ? (
								<>
									{content?.leftPanel?.map((val, i) => (
										<>
											<div className="data-obs-text-wrap">
												<h3>{val?.title?.[lang]}</h3>
												<div
													dangerouslySetInnerHTML={{
														__html: val?.description?.[lang],
													}}
												/>
											</div>
										</>
									))}
								</>
							) : (
								''
							)}
							{/* <div className="data-obs-text-wrap">
								<h3>{content?.title?.[lang]}</h3>
								<div
									dangerouslySetInnerHTML={{
										__html: content?.description?.[lang],
									}}
								/>
							</div> */}
							<div className="reported-list-wrap">
								<div className="reported-list-head">{t('data_obser.published_reports_lists')}</div>
								<div className="reported-list-box">
									{publishedReport.length > 0 ? (
										publishedReport?.map((val, i) => (
											<div className="reported-box">
												<h4>{val?.subject?.en}</h4>
												<p>{val?.description?.en}</p>
												<ul>
													<li>
														{t('user_list.date_of_the_publication')} : {moment(val?.created_at).format('DD/MM/YYYY')}
													</li>
													{val?.attach_file ? (
														<li>
															{t('data_obser.uploaded_file')} :{' '}
															<span>
																<strong></strong>
																<a href={val?.media_path + '/' + val?.attach_file}>
																	<i className="fas fa-download"></i>
																</a>
															</span>
														</li>
													) : (
														''
													)}
												</ul>
											</div>
										))
									) : (
										<DataNotFound text={'No Data Found'} />
									)}
								</div>
								<div className="popularBox-paginate mt-md-4 mt-3 mb-lg-0 mb-3">
									{publishedReport.length > 0 && (
										<ReactPaginate
											previousLabel={t('previos')}
											nextLabel={t('next')}
											breakLabel={'...'}
											pageCount={publishedReportMeta?.totalPages}
											marginPagesDisplayed={2}
											pageRangeDisplayed={3}
											onPageChange={(page) => setPage(page.selected + 1)}
											containerClassName={'pagination justify-content-center custom-paginate'}
											pageClassName={'page-item'}
											pageLinkClassName={'page-link'}
											previousClassName={'page-item'}
											previousLinkClassName={'page-link'}
											nextClassName={'page-item'}
											nextLinkClassName={'page-link'}
											breakClassName={'page-item'}
											breakLinkClassName={'page-link'}
											activeClassName={'active'}
										/>
									)}
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-5 col-sm-12 col-12">
							<div className="weather-update-wrap">
								<div className="weather-update-head">
									<div className="row justify-content-between align-items-center">
										<div className="col-auto">
											<div className="weather-heading">{t('data_obser.weather_update')}</div>
										</div>
										<div className="col-auto">
											<button type="submit" className="weather-refree-btn">
												<i className="fas fa-sync" />
											</button>
										</div>
									</div>
								</div>
								<div className="weather-update-box">
									<div className="row">
										<div className="col-lg-6 col-md-12 col-sm-6 col-12">
											<div className="weather-data-box weather-cloud">
												<ul className="d-flex justify-content-between">
													<li>Nouakchott</li>
													<li>
														<i className="fas fa-cloud" />
													</li>
												</ul>
												<h3>
													15 <span className="weather-deg" />
												</h3>
												<p>Lorem Ipsum</p>
											</div>
										</div>
										<div className="col-lg-6 col-md-12 col-sm-6 col-12">
											<div className="weather-data-box weather-sun">
												<ul className="d-flex justify-content-between">
													<li>Nouakchott</li>
													<li>
														<i className="fas fa-sun" />
													</li>
												</ul>
												<h3>
													15 <span className="weather-deg" />
												</h3>
												<p>Lorem Ipsum</p>
											</div>
										</div>
									</div>
									<a href="#" className="weathe-letest">
										Latest Update
									</a>
								</div>
							</div>
							<div className="letest-event-slider">
								<div className="upcoming-event">{t('data_obser.upcoming_event')}</div>
								{lang === 'ar' ? (
									<EqualHeight>
										<OwlCarouselRtl className="owl-theme slider-arrow-btm" {...options}>
											{Eventcontent?.map((val, i) => (
												<div className="item">
													<div class="events-box">
														<div className="events-box-desc">
															<span className="events-box-img">
																<img className="img-block" src={val?.image ? val?.image : about_bg} alt="event title" />
															</span>
															<span className="events-type">
																{t('event.event_type')} : {val?.get_event_type?.name?.[lang]}
															</span>
															<EqualHeightElement name="eventTitle">
																<h4>
																	<a href="javascript:;">{val?.title?.[lang]}</a>
																</h4>
															</EqualHeightElement>
															<EqualHeightElement name="eventDescs">
																<p>{val?.description?.[lang]}</p>
															</EqualHeightElement>
														</div>
														<div className="event-organizer">
															<p>
																{t('event.organizer')} : {val?.organizer_name}
															</p>
															<ul class="event-organizer-info">
																<li>
																	{t('order_sucess.phone')} : <span>{val?.organizer_phone_no}</span>
																</li>
																<li>
																	{t('admin_user.email')} : <span>{val?.organizer_email}</span>
																</li>
															</ul>
														</div>
														<ul class="events-book-info">
															<li>
																{t('event.location')} : <span>{val?.location}</span>
															</li>
															<li>
																{t('order_sucess.date')} :<span>{moment(val?.event_date).format('DD/MM/YYYY')}</span>
															</li>
															<li>
																{t('event.time')} : <span>{val?.event_time}</span>
															</li>
														</ul>
														<div className="register-event">
															<button
																type="button"
																className="register-event-reg"
																onClick={() => {
																	openModal(val.id);
																}}
															>
																{t('order_sucess.register')}
															</button>
															<ModalEventRegister
																title="Custom Modal"
																content="This is a custom modal created in React."
																isOpen={openModals[val.id] || false}
																onClose={() => closeModal(val.id)}
																eventId={val.id}
															/>
														</div>
													</div>
												</div>
											))}
										</OwlCarouselRtl>
									</EqualHeight>
								) : (
									<EqualHeight>
										<OwlCarousel className="owl-theme slider-arrow-btm" {...options}>
											{Eventcontent?.map((val, i) => (
												<div className="item">
													<div class="events-box">
														<div className="events-box-desc">
															<span className="events-box-img">
																<img className="img-block" src={val?.image ? val?.image : about_bg} alt="Event Content" />
															</span>
															<span className="events-type">
																{t('event.event_type')} : {val?.get_event_type?.name?.[lang]}
															</span>
															<EqualHeightElement name="eventTitle">
																<h4>
																	<a href="javascript:;">{val?.title?.[lang]}</a>
																</h4>
															</EqualHeightElement>
															<EqualHeightElement name="eventDescs">
																<p>{val?.description?.[lang]}</p>
															</EqualHeightElement>
														</div>
														<div className="event-organizer">
															<p>
																{t('event.organizer')} : {val?.organizer_name}
															</p>
															<ul class="event-organizer-info">
																<li>
																	{t('order_sucess.phone')} : <span>{val?.organizer_phone_no}</span>
																</li>
																<li>
																	{t('admin_user.email')} : <span>{val?.organizer_email}</span>
																</li>
															</ul>
														</div>
														<ul class="events-book-info">
															<li>
																{t('event.location')} : <span>{val?.location}</span>
															</li>
															<li>
																{t('order_sucess.date')} :<span>{moment(val?.event_date).format('DD/MM/YYYY')}</span>
															</li>
															<li>
																{t('event.time')} : <span>{val?.event_time}</span>
															</li>
														</ul>
														<div className="register-event">
															<button
																type="button"
																className="register-event-reg"
																onClick={() => {
																	openModal(val.id);
																}}
															>
																{t('order_sucess.register')}
															</button>
														</div>
													</div>
												</div>
											))}
										</OwlCarousel>
									</EqualHeight>
								)}
							</div>
							{content?.blog?.length > 0 ? (
								<>
									{content?.blog?.map((val, i) => (
										<>
											<div className="data-post-box">
												<span className="post-box-img">
													<img className="img-block" src={val?.img} alt="Blog" />
												</span>
												<h4
													dangerouslySetInnerHTML={{
														__html: val?.description?.[lang],
													}}
												/>
											</div>
										</>
									))}
								</>
							) : (
								<>
									<div className="data-post-box">
										<span className="post-box-img">
											<img className="img-block" src={data_rgt} alt="temp image" />
										</span>
										<h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </h4>
									</div>
									<div className="data-post-box">
										<span className="post-box-img">
											<img className="img-block" src={about_bg} alt="about temp desc" />
										</span>
										<h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </h4>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</section>
			<ModalEventRegister
				title="Custom Modal"
				content="This is a custom modal created in React."
				isOpen={openModals[event_id] || false}
				onClose={() => closeModal(event_id)}
				eventId={event_id}
			/>
			<Footer />
		</>
	);
}

export default DataObservatory;
