import React, { useEffect, useState } from 'react';
import './Register.scss';
import LoginBg from 'assets/images/loginbg.jpg';
import LoginReglogo from 'assets/images/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import InputErrorMsg from 'components/InputErrorMsg';
import Alert from 'components/Alert';
import { registerUser, listCountry, listRegion, sendOtpService } from './Register.service.js';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import allActions from 'redux/actions';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import OtpInput from 'react-otp-input';

function Register() {
	const { t } = useTranslation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [countryList, SetCountryList] = useState([]);
	const [regionList, SetRegionList] = useState([]);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [userData, setUserData] = useState({});
	const [otp, setOtp] = useState('');
	const [deliveryRegionList, setDeliveryRegionList] = useState([]);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const selectedLang = localStorage.getItem('i18nextLng').split('-')[0] || 'en';

	const lang = useSelector((state) => state.lang);
	let dir = 'ltr';
	dir = lang === 'ar' ? 'rtl' : 'ltr';
	const [selectedOption, setSelectedOption] = useState('option1');

	useEffect(() => {
		getCountry();
		getRegion();
	}, []);

	useEffect(() => {
		document.body.dir = dir;
		document.body.className = `style-${dir}`;
		dispatch(allActions.langAction.changeLanguage(selectedLang));
	}, [lang]);

	const getCountry = async () => {
		let result = await listCountry();
		if (result.data.status === 200) {
			let country = result.data.data.country;
			let list = country.map((res, i) => {
				return { value: res.id, label: res.name };
			});
			SetCountryList(list);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};
	const onSubmit = async (data) => {
		dispatch(allActions.loadingAction.showLoading());
		let result = await registerUser(data);
		dispatch(allActions.loadingAction.hideLoading());
		if (result.data.status === 200) {
			toast.success(t('registration_successful'));
			reset();

			setTimeout(() => {
				navigate('/');
			}, 2000);
		} else {
			toast.error(result.data.error);
		}
		window.scrollTo(0, 0);
	};
	const getRegion = async () => {
		let result = await listRegion();
		if (result.data.status === 200) {
			let region = result.data.data.region;
			SetRegionList(region);
			let delivery_region = result.data.data.region.map((res, i) => {
				return { value: res.id, label: res.name };
			});
			setDeliveryRegionList(delivery_region);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};

	const verifyOtp = async () => {
		if (otp === localStorage.getItem('otp')) {
			dispatch(allActions.loadingAction.showLoading());
			let result = await registerUser(userData);
			dispatch(allActions.loadingAction.hideLoading());
			if (result.data.status === 200) {
				document.querySelector('.btn-close').click();
				toast.success(t('registration_successful'));

				setTimeout(() => {
					navigate('/');
				}, 2000);
			} else {
				toast.error(result.data.error);
			}
			window.scrollTo(0, 0);
		} else {
			toast.error('Otp not matched !');
		}
	};

	const resendOtp = async () => {
		dispatch(allActions.loadingAction.showLoading());
		let result = await sendOtpService(userData);
		dispatch(allActions.loadingAction.hideLoading());
		if (result.data.status === 200) {
			localStorage.setItem('otp', result.data.data.otp);
		} else {
			toast.error(result.data.error);
		}
	};
	const handleRadioChange = async (event) => {
		setSelectedOption(event.target.value);
	};
	return (
		<>
			<section className="login-regbg d-flex align-items-center justify-content-center" style={{ background: `url(${LoginBg})` }}>
				<div className="login-reg-wrap">
					<div className="login-reg-box position-relative">
						<div className="log-back-home">
							<Link to="/" className="log-back-home-btn">
								<i className="fas fa-home"></i>
							</Link>
						</div>
						<span className="log-reg-logo">
							<img className="img-block" src={LoginReglogo} alt="LoginReglogo" />
						</span>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="row">
								<div className="col-12">{alertType && <Alert type={alertType} message={alertMessage} />}</div>
								<div className="col-12">
									<div className="register-catagory mb-2">
										<p>{t('i_am')}</p>
										<ul className="d-flex flex-wrap">
											<li className="radio">
												<input
													type="radio"
													id="role1"
													value={3}
													name="role"
													onClick={handleRadioChange}
													{...register('role', {
														required: t('user_list.role_is_required'),
													})}
												/>
												<label htmlFor="role1">{t('buyer')}</label>
											</li>
											<li className="radio">
												<input
													type="radio"
													id="role2"
													value={2}
													name="role"
													onClick={handleRadioChange}
													{...register('role', {
														required: t('user_list.role_is_required'),
													})}
												/>
												<label htmlFor="role2"> {t('seller')} </label>
											</li>
											<li className="radio">
												<input
													type="radio"
													id="role3"
													value={4}
													name="role"
													onClick={handleRadioChange}
													{...register('role', {
														required: t('user_list.role_is_required'),
													})}
												/>
												<label htmlFor="role3"> {t('delivery_service')} </label>
											</li>
											<li className="radio">
												<input
													type="radio"
													id="role4"
													value={5}
													name="role"
													onClick={handleRadioChange}
													{...register('role', {
														required: t('user_list.role_is_required'),
													})}
												/>
												<label htmlFor="role4"> {t('payment_agent')} </label>
											</li>
											<li className="radio">
												<input
													type="radio"
													id="role5"
													value={7}
													name="role"
													onClick={handleRadioChange}
													{...register('role', {
														required: t('user_list.role_is_required'),
													})}
												/>
												<label htmlFor="role5"> {t('agricultural_extension_agents')} </label>
											</li>
											<li className="radio">
												<input
													type="radio"
													id="role6"
													value={8}
													name="role"
													onClick={handleRadioChange}
													{...register('role', {
														required: t('user_list.role_is_required'),
													})}
												/>
												<label htmlFor="role6"> {t('startup')} </label>
											</li>
										</ul>

										{errors.role && <InputErrorMsg error={errors.role?.message} />}
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="login-input">
										<label className="lbl-heading">{t('first_name')}</label>
										<input
											type="text"
											className="form-control login-input-style"
											placeholder={t('first_name')}
											{...register('first_name', {
												required: t('first_name_is_required'),
											})}
										/>
										{errors.first_name && <InputErrorMsg error={errors.first_name?.message} />}
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="login-input">
										<label className="lbl-heading">{t('last_name')}</label>
										<input
											type="text"
											className="form-control login-input-style"
											placeholder={t('last_name')}
											{...register('last_name', {
												required: t('last_name_is_required'),
											})}
										/>
										{errors.last_name && <InputErrorMsg error={errors.last_name?.message} />}
									</div>
								</div>
								<div className="col-12">
									<div className="login-input">
										<label className="lbl-heading">{t('email')}</label>
										<input
											type="text"
											className="form-control login-input-style"
											placeholder={t('email')}
											{...register('email', {
												required: t('email_is_reqired'),
											})}
										/>
										{errors.email && <InputErrorMsg error={errors.email?.message} />}
									</div>
								</div>
								<div className="col-12">
									<div className="login-input">
										<label className="lbl-heading">{t('company_name')}</label>
										<input type="text" className="form-control login-input-style" placeholder={t('company_name')} {...register('company_name')} />
										{errors.company_name && <InputErrorMsg error={errors.company_name?.message} />}
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="login-input">
										<label className="lbl-heading">{t('company_vat')}</label>
										<input type="text" className="form-control login-input-style" placeholder={t('company_vat')} {...register('company_vat')} />
										{errors.company_vat && <InputErrorMsg error={errors.company_vat?.message} />}
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="login-input">
										<label className="lbl-heading">{t('phone_no')}</label>
										<input
											type="text"
											className="form-control login-input-style"
											placeholder={t('phone_no')}
											{...register('ph_no', {
												required: t('phone_no_is_required'),
											})}
										/>
										{errors.ph_no && <InputErrorMsg error={errors.ph_no?.message} />}
									</div>
								</div>
								<div className="col-12">
									<div className="login-input">
										<label className="lbl-heading">{t('address')}</label>
										<input
											type="text"
											className="form-control login-input-style"
											placeholder={t('address')}
											{...register('address', {
												required: t('address_is_required'),
											})}
										/>
										{errors.address && <InputErrorMsg error={errors.address?.message} />}
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="login-input">
										<label className="lbl-heading">{t('zip_code')}</label>
										<input type="text" className="form-control login-input-style" placeholder={t('zip_code')} {...register('zip_code')} />
										{errors.zip_code && <InputErrorMsg error={errors.zip_code?.message} />}
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="login-select">
										<label className="lbl-heading">{t('region')}</label>
										<select
											className="form-control login-select-style selectOption-green"
											{...register('region', {
												required: t('region_is_required'),
											})}
										>
											<option value="">{t('select')}</option>
											{regionList?.map((value, index) => {
												return (
													<option key={index} value={value.id}>
														{value.name}
													</option>
												);
											})}
										</select>
										{errors.region && <InputErrorMsg error={errors.region?.message} />}
									</div>
								</div>
								<div className="col-lg-12 col-md-12 col-sm-12 col-12">
									<div className="login-input">
										<label className="lbl-heading">{t('city')}</label>
										<input
											type="text"
											className="form-control login-input-style"
											placeholder={t('city')}
											{...register('city', { required: t('city_is_required') })}
										/>
										{errors.city && <InputErrorMsg error={errors.city?.message} />}
									</div>
								</div>
								{selectedOption === '4' && (
									<div className="col-lg-12 col-md-12 col-sm-12 col-12">
										<div className="login-select login-multi-select">
											<label className="lbl-heading">{'Delevery Region'}</label>
											<Controller
												name="delivery_region"
												control={control}
												render={({ field }) => (
													<Select isMulti {...field} options={deliveryRegionList} /* onChange={(e) => { field.onChange(e); categoryChange(e) }} */ />
												)}
											/>
										</div>
									</div>
								)}
							</div>
							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="login-input">
										<label className="lbl-heading">{t('password')}</label>
										<div className="add-icon">
											<input
												type={showPassword ? 'text' : 'password'}
												className="form-control login-input-style"
												placeholder={t('enter_your_password')}
												{...register('password', {
													required: t('password_is_reqired'),
												})}
											/>
											<span className="pass-icon">
												<i
													className={showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'}
													onClick={() => {
														setShowPassword(!showPassword);
													}}
												></i>
											</span>
										</div>
										{errors.password && <InputErrorMsg error={errors.password?.message} />}
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="login-input">
										<label className="lbl-heading">{t('confirm_password')}</label>
										<div className="add-icon">
											<input
												type={showConfirmPassword ? 'text' : 'password'}
												className="form-control login-input-style"
												placeholder={t('enter_your_password_again')}
												{...register('password_confirmation', {
													required: t('confirm_password_is_required'),
												})}
											/>
											<span className="pass-icon">
												<i
													className={showConfirmPassword ? 'fas fa-eye' : 'fas fa-eye-slash'}
													onClick={() => {
														setShowConfirmPassword(!showConfirmPassword);
													}}
												></i>
											</span>
										</div>
										{errors.password_confirmation && <InputErrorMsg error={errors.password_confirmation?.message} />}
									</div>
								</div>
							</div>
							<div className="logreg-btn-wrap">
								<button type="submit" className="logreg-btn">
									{t('register')}
								</button>
								<p>
									{t('have_an_account')}{' '}
									<Link to="/login" className="logreg-btn2">
										{t('log_in')}
									</Link>
								</p>
							</div>
						</form>
					</div>
				</div>
			</section>
			<button type="button" className="btn btn-primary d-none" id="open_modal" data-bs-toggle="modal" data-bs-target="#otpModal">
				Launch demo modal
			</button>

			<div
				className="modal fade cmn-blk-modal"
				id="otpModal"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="otpModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="otpModalLabel">
								OTP
							</h5>
							<button type="button" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close" />
						</div>
						<div className="modal-body">
							<div className="otp-input-wrap">
								<OtpInput
									value={otp}
									onChange={(otp) => setOtp(otp)}
									containerStyle={'otp-box otp-box-list'}
									inputStyle={'login-input-style'}
									numInputs={6}
									isInputNum
								/>
							</div>
							<div class="logreg-btn-wrap">
								<button type="button" class="logreg-btn" onClick={verifyOtp}>
									{t('user_list.verify')}
								</button>
								<p>
									{t('user_list.didnt_received_yet')}{' '}
									<Link class="logreg-btn2" to="#" onClick={resendOtp}>
										{t('user_list.resend_otp')}
									</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Register;
