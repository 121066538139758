import React, { useEffect, useMemo, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import logo from 'assets/images/logo.png';
import allActions from 'redux/actions';
import './Sidebar.scss';
import { useTranslation } from 'react-i18next';

function Sidebar() {
	const dispatch = useDispatch();
	const [windowHeight, setWindowHeight] = useState(1000);
	useEffect(() => {
		setWindowHeight(window.innerWidth);
	}, []);

	const collapse = useSelector((state) => state.collapseSidebar);
	const auth = useSelector((state) => state.auth);

	useMemo(() => {
		if (windowHeight < 991.98) {
			const track = document.querySelectorAll('.sidebar-item');
			track.forEach((el) => {
				el.onclick = (event) => {
					dispatch(allActions.sidebarAction.toggleSidebar());
				};
			});
		}
	}, [windowHeight, auth]);
	//Language
	const { t } = useTranslation();
	return (
		<>
			<nav id="sidebar" className={collapse ? 'sidebar js-sidebar collapsed' : 'sidebar js-sidebar'}>
				<SimpleBar style={{ maxHeight: '100vh' }}>
					<div className="sidebar-content js-simplebar">
						<Link className="sidebar-brand d-flex align-items-center" to="/">
							<div className="sidebar-logo-wrap">
								<span className="sidebar-logo">
									<img className="img-block" src={logo} alt="logo" />
								</span>
							</div>
							<span className="align-middle">{t('left_panel.hassad')}</span>
						</Link>
						<ul className="sidebar-nav">
							<li className="sidebar-header">{t('admin_dashboard_menu.pages')}</li>
							<li className="sidebar-item ">
								<NavLink className="sidebar-link" to="/admin/dashboard">
									<i className="fas fa-tachometer-alt"></i>
									<span className="align-middle">{t('admin_dashboard_menu.dashboard')}</span>
								</NavLink>
							</li>
							{auth.role === 1 && (
								<li className="sidebar-item">
									<NavLink className="sidebar-link" to="/admin/users">
										<i className="fas fa-user"></i> <span className="align-middle">{t('admin_dashboard_menu.users')}</span>
									</NavLink>
								</li>
							)}

							<li className="sidebar-item">
								<NavLink className="sidebar-link" to="/admin/profile">
									<i className="fas fa-user-circle"></i>
									<span className="align-middle">{t('admin_dashboard_menu.profile')}</span>
								</NavLink>
							</li>
							{(auth.role === 2 || auth.role === 6 || auth.role === 8 || auth.role === 1) && (
								<li className="sidebar-item">
									<NavLink className="sidebar-link" to="/admin/bank-details">
										<i className="fas fa-university"></i>
										<span className="align-middle">{t('admin_dashboard_menu.bank_details')}</span>
									</NavLink>
								</li>
							)}
							<li className="sidebar-item">
								<NavLink className="sidebar-link" to="/admin/my-donation">
									<i className="fas fa-hand-holding-usd"></i>
									<span className="align-middle">{t('admin_dashboard_menu.my_donation')}</span>
								</NavLink>
							</li>
							{(auth.role === 1 || auth.role === 2) && <li className="sidebar-header">{t('admin_dashboard_menu.product')}</li>}
							{auth.role === 1 && (
								<>
									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/category/list">
											<i className="fas fa-cube"></i>
											<span className="align-middle">{t('admin_dashboard_menu.category')}</span>
										</NavLink>
									</li>
									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/sub-category/list">
											<i className="fas fa-cubes"></i>
											<span className="align-middle">{t('admin_dashboard_menu.sub_category')}</span>
										</NavLink>
									</li>
									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/product/list">
											<i className="fas fa-box-open"></i>
											<span className="align-middle">{t('admin_dashboard_menu.product_type')}</span>
										</NavLink>
									</li>
									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/product/product-request">
											<i className="fas fa-box-open"></i>
											<span className="align-middle">{t('admin_dashboard_menu.request_product')}</span>
										</NavLink>
									</li>

									<li className="sidebar-header">{t('admin_dashboard_menu.orders')}</li>
									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/order/list">
											<i className="fas fa-shopping-bag"></i>
											<span className="align-middle">{t('admin_dashboard_menu.order_list')}</span>
										</NavLink>
									</li>
									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/complete-order/list">
											<i className="fas fa-shopping-bag"></i>
											<span className="align-middle">{t('admin_dashboard_menu.complete_order_list')}</span>
										</NavLink>
									</li>
									<li className="sidebar-header">{t('admin_dashboard_menu.fund')}</li>
									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/fund/category">
											<i className="fas fa-shopping-bag"></i>
											<span className="align-middle">{t('admin_dashboard_menu.category')}</span>
										</NavLink>
									</li>
									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/fund/raise-fund-list">
											<i className="fas fa-hand-holding-usd"></i>
											<span className="align-middle">{t('admin_dashboard_menu.raise_fund_list')}</span>
										</NavLink>
									</li>
									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/fund/donaiton-list">
											<i className="fas fa-hand-holding-usd"></i>
											<span className="align-middle">{t('admin_dashboard_menu.donation_list')}</span>
										</NavLink>
									</li>

									<li className="sidebar-header">{t('admin_dashboard_menu.content_management')}</li>

									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/cms/about-us">
											<i className="fas fa-file-alt"></i>
											<span className="align-middle">{t('admin_dashboard_menu.about_us')}</span>
										</NavLink>
									</li>
									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/cms/data-observatory">
											<i className="fas fa-file-alt"></i>
											<span className="align-middle">{t('admin_dashboard_menu.data_observatory')}</span>
										</NavLink>
									</li>
									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/cms/home">
											<i className="fas fa-file-alt"></i>
											<span className="align-middle">{t('admin_dashboard_menu.home')}</span>
										</NavLink>
									</li>

									<li className="sidebar-header">{t('admin_dashboard_menu.payout')}</li>
									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/payout/order-list">
											<i className="fas fa-file-alt"></i>
											<span className="align-middle">{t('admin_dashboard_menu.order_list')}</span>
										</NavLink>
									</li>
								</>
							)}
							{auth.role === 2 && (
								<>
									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/vendor-product/list">
											<i className="fas fa-box-open"></i>
											<span className="align-middle">{t('admin_dashboard_menu.product')}</span>
										</NavLink>
									</li>
									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/vendor-product-req/list">
											<i className="fas fa-box-open"></i>
											<span className="align-middle">{t('admin_dashboard_menu.request_product')}</span>
										</NavLink>
									</li>
									<li className="sidebar-header">{t('admin_dashboard_menu.orders')}</li>
									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/vendor-order/list">
											<i className="fas fa-shopping-bag"></i>
											<span className="align-middle">{t('admin_dashboard_menu.order_list')}</span>
										</NavLink>
									</li>
									<li className="sidebar-header">{t('fund_raise.fund_raise')}</li>
									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/fund-raiser/list-fund">
											<i className="fas fa-shopping-bag"></i>
											<span className="align-middle">{t('left_panel.list_fund')}</span>
										</NavLink>
									</li>
									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/fund/donaiton-list">
											<i className="fas fa-hand-holding-usd"></i>
											<span className="align-middle">{t('admin_dashboard_menu.donation_list')}</span>
										</NavLink>
									</li>
								</>
							)}
							{auth.role === 4 && (
								<>
									<li className="sidebar-header">{t('admin_dashboard_menu.orders')}</li>
									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/delivery-order/list">
											<i className="fas fa-shopping-bag"></i>
											<span className="align-middle">{t('admin_dashboard_menu.order_list')}</span>
										</NavLink>
									</li>
								</>
							)}
							{(auth.role === 6 || auth.role === 8) && (
								<>
									<li className="sidebar-header">{t('fund_raise.fund_raise')}</li>
									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/fund-raiser/list-fund">
											<i className="fas fa-shopping-bag"></i>
											<span className="align-middle">{t('left_panel.list_fund')}</span>
										</NavLink>
									</li>
									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/fund/donaiton-list">
											<i className="fas fa-hand-holding-usd"></i>
											<span className="align-middle">{t('admin_dashboard_menu.donation_list')}</span>
										</NavLink>
									</li>
								</>
							)}
							{(auth.role === 7 || auth.role === 1) && (
								<>
									<li className="sidebar-header">{t('event.event')}</li>
									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/event/list">
											<i className="fas fa-shopping-bag"></i>
											<span className="align-middle">{t('left_panel.list_event')}</span>
										</NavLink>
									</li>
									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/event/register/list">
											<i className="fas fa-shopping-bag"></i>
											<span className="align-middle">{t('left_panel.list_user_register_event')}</span>
										</NavLink>
									</li>
								</>
							)}
							{(auth.role === 7 || auth.role === 1) && (
								<>
									<li className="sidebar-header">{t('admin_dashboard_menu.request_form')}</li>
									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/request-form/list">
											<i className="fas fa-shopping-bag"></i>
											<span className="align-middle">{t('admin_dashboard_menu.list_request_form')}</span>
										</NavLink>
									</li>
								</>
							)}
							<li className="sidebar-header">{t('admin_dashboard_menu.forum')}</li>
							{
								// auth.role !== 1 &&
								<>
									<li className="sidebar-item">
										<NavLink className="sidebar-link" to="/admin/forum/list">
											<i className="fas fa-shopping-bag"></i>
											<span className="align-middle">{t('admin_dashboard_menu.forum')}</span>
										</NavLink>
									</li>
								</>
							}
						</ul>
					</div>
				</SimpleBar>
			</nav>
		</>
	);
}

export default Sidebar;
