import React, { useEffect, useState } from 'react';
import Breadcrumb from 'components/Admin/Breadcrumb';
import Alert from 'components/Alert';
import { listProduct, deleteProduct } from './ListProduct.service.js';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import './ListProduct.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function ListProduct() {
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [products, setProducts] = useState([]);
	const [productMeta, setProductMeta] = useState({});
	const [page, setPage] = useState(1);
	const lang = useSelector((state) => state.lang);
	const { t } = useTranslation();

	useEffect(() => {
		getProductList();
	}, [page]);

	const getProductList = async () => {
		let list = await listProduct(page);
		if (list.data.status === 200) {
			setProducts(list.data.data.records);
			setProductMeta(list.data.data.meta);
		} else {
			SetAlertType('error');
			SetAlertMessage(list.data.error);
			window.scrollTo(0, 0);
		}
	};

	const deleteProd = async (id) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="custom-confirmation-box">
						<h1>{t('admin_product_type.are_you_sure')}</h1>
						<p>{t('admin_product_type.you_want_to_delete_this')}</p>
						<button onClick={onClose} className="no-btn">
							{t('admin_product_type.no')}
						</button>
						<button
							className="yes-btn"
							onClick={async () => {
								let result = await deleteProduct(id);
								if (result.data.status === 200) {
									getProductList();
									SetAlertType('success');
									SetAlertMessage(`{${t('vendor.product_deleted_successfully')}} !`);
								} else {
									SetAlertType('error');
									SetAlertMessage(result.data.error);
								}
								onClose();
								window.scrollTo(0, 0);
							}}
						>
							{t('admin_user.yes_delete_it')}
						</button>
					</div>
				);
			},
		});
	};

	const columns = [
		{
			name: t('admin_product_type.image'),
			cell: (row) => (
				<span className="list-table-img">
					<img className="img-block" src={row?.image} alt="Product" />
				</span>
			),
		},
		{
			name: t('admin_product_type.type_name'),
			selector: (row) => row?.name?.[lang],
			sortable: true,
		},
		{
			name: t('admin_product_type.action'),
			cell: (row) => (
				<div className="dropdown">
					<div className="actionList " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
						<svg
							style={{ cursor: 'pointer' }}
							xmlns="http://www.w3.org/2000/svg"
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth={2}
							strokeLinecap="round"
							strokeLinejoin="round"
							className="feather feather-sliders dropdown-toggle"
							data-toggle="dropdown"
							role="button"
							aria-expanded="false"
						>
							<line x1={4} y1={21} x2={4} y2={14} />
							<line x1={4} y1={10} x2={4} y2={3} />
							<line x1={12} y1={21} x2={12} y2={12} />
							<line x1={12} y1={8} x2={12} y2={3} />
							<line x1={20} y1={21} x2={20} y2={16} />
							<line x1={20} y1={12} x2={20} y2={3} />
							<line x1={1} y1={14} x2={7} y2={14} />
							<line x1={9} y1={8} x2={15} y2={8} />
							<line x1={17} y1={16} x2={23} y2={16} />
						</svg>
					</div>
					<div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
						<Link to={`/admin/product/edit/${btoa(row.id)}`} className="dropdown-item">
							{t('admin_product_type.edit')}
						</Link>
						<Link
							to="#"
							className="dropdown-item"
							onClick={() => {
								deleteProd(row.id);
							}}
						>
							{t('admin_product_type.delete')}
						</Link>
					</div>
				</div>
			),
		},
	];

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('admin_product_type.product_type') },
					{ link: '#', name: t('admin_product_type.list') },
				]}
				title={t('admin_product_type.product_type')}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}
			<div className="cmn-a-tag-wrap mb-3">
				<Link to={`/admin/product/add`} className="cmn-a-tag">
					{t('admin_product_type.add_product_type')}
				</Link>
			</div>
			<div className="datatable-wrap">
				<DataTable
					columns={columns}
					data={products}
					striped
					pagination
					paginationServer
					paginationTotalRows={productMeta.totalRecords}
					paginationPerPage={15}
					paginationComponentOptions={{
						noRowsPerPage: true,
					}}
					onChangePage={(page) => setPage(page)}
				/>
			</div>
		</>
	);
}

export default ListProduct;
