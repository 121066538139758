import { default as api } from 'config/adminApiConf.js';

export const editRequestFormService = async (id, data) => {
	try {
		let result = await api.put(`/request-form/edit/${id}`, data);
		return result;
	} catch (error) {
		return error;
	}
};
export const requestFormDetailsService = async (id) => {
	try {
		let result = await api.get(`/request-form/details/${id}`);
		return result;
	} catch (error) {
		return error;
	}
};
export const listRequestType = async (data) => {
	try {
		let result = await api.get(`/request-type`, data);
		return result;
	} catch (error) {
		return error;
	}
};
