import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import InputErrorMsg from 'components/InputErrorMsg';
import Select from 'react-select';
import Breadcrumb from 'components/Admin/Breadcrumb';
import Alert from 'components/Alert';
import './EditFundRaise.scss';
import { toast } from 'react-toastify';
import { listRegion, fundRaiseCatListService, fundRaiseDetailsService, editRaiseFundService } from './EditFundRaise.service.js';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function EditFundRaise() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();

	const { t } = useTranslation();
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [uploadImage, setUploadImage] = useState([]);
	const [uploadDocument, setUploadDocument] = useState([]);
	const [regionList, setRegionList] = useState([]);
	const [category, setCategory] = useState([]);
	const [fundRaiseDetails, setFundRaiseDetails] = useState([]);
	const lang = useSelector((state) => state.lang);
	const params = useParams();
	const id = atob(params.id);
	useEffect(() => {
		const scrollContainer = document.querySelector('.file-scroll');

		scrollContainer.addEventListener('wheel', (evt) => {
			evt.preventDefault();
			scrollContainer.scrollLeft += evt.deltaY;
		});
		getRegion();
		getCategoryList();
		getFundRaiseDetails();
	}, [lang]);

	const unique = (value, index, self) => {
		return self.indexOf(value) === index;
	};
	const handleFile = async (e) => {
		let files = Array.from(e.target.files);
		Promise.all(
			files.map((file) => {
				return getBase64(file);
			}),
		).then(
			(images) => {
				let datas = [...images, ...uploadImage];
				let uniquedata = datas.filter(unique);
				setUploadImage(uniquedata);
			},
			(error) => {
				console.error(error);
			},
		);
	};
	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				resolve(baseURL);
			};
		});
	};

	function deleteFile(e) {
		const img = uploadImage.filter((item, index) => index !== e);
		setUploadImage(img);
	}

	const getRegion = async () => {
		let result = await listRegion();
		if (result.data.status === 200) {
			let region = result.data.data.region;
			setRegionList(region);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};

	const getFundRaiseDetails = async () => {
		const result = await fundRaiseDetailsService(id);
		if (result.data.status === 200) {
			setFundRaiseDetails(result.data.data.raiseFund);
		} else {
			toast.error(result.data.error);
		}
	};

	useEffect(() => {
		setDefaultValueForm();
	}, [fundRaiseDetails]);

	const setDefaultValueForm = async () => {
		let defaultValues = {};
		defaultValues.campaign_title = fundRaiseDetails?.campaign_title?.en;
		defaultValues.campaign_title_fr = fundRaiseDetails?.campaign_title?.fr;
		defaultValues.campaign_title_ar = fundRaiseDetails?.campaign_title?.ar;
		defaultValues.Campaign_short_description = fundRaiseDetails?.campaign_short_description?.en;
		defaultValues.Campaign_description = fundRaiseDetails?.campaign_description?.en;
		defaultValues.Campaign_description_fr = fundRaiseDetails?.campaign_description?.fr;
		defaultValues.Campaign_description_ar = fundRaiseDetails?.campaign_description?.ar;
		defaultValues.category = {
			value: fundRaiseDetails?.get_category?.id,
			label: fundRaiseDetails?.get_category?.name?.en,
		};
		defaultValues.campaign_end_date = fundRaiseDetails?.campaign_end_date;
		defaultValues.region = fundRaiseDetails?.region_id;
		defaultValues.city = fundRaiseDetails?.city;
		defaultValues.address = fundRaiseDetails?.address;
		defaultValues.budget_needed = fundRaiseDetails?.budget_needed;
		defaultValues.organizer_name = fundRaiseDetails?.organizer_name;
		reset({ ...defaultValues });

		var images = [
			{
				media_path: '',
				media_name: '',
			},
		];
		if (fundRaiseDetails?.get_images?.length > 0) {
			images = fundRaiseDetails?.get_images?.map((val, i) => {
				return {
					media_path: val?.media_path,
					media_name: val?.media_name,
				};
			});
		}
		await setUploadImage(images);
	};

	const onSubmit = async (data) => {
		let formData = {
			campaign_title: data.campaign_title,
			campaign_title_fr: data.campaign_title_fr,
			campaign_title_ar: data.campaign_title_ar,
			Campaign_short_description: data.Campaign_short_description,
			Campaign_description: data.Campaign_description,
			Campaign_description_fr: data.Campaign_description_fr,
			Campaign_description_ar: data.Campaign_description_ar,
			category: data.category?.value,
			campaign_end_date: data.campaign_end_date,
			region: data.region,
			city: data.city,
			address: data.address,
			budget_needed: data.budget_needed,
			image: JSON.stringify(uploadImage),
		};
		let result = await editRaiseFundService(id, formData);

		if (result.data.status === 200) {
			SetAlertType('success');
			SetAlertMessage(`${t('fund_raise.fund_raised_updated_successfully')} !`);
			reset();
			setUploadImage([]);
			getFundRaiseDetails();
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
		}
		window.scrollTo(0, 0);
	};

	const handleDocument = async (e) => {
		let f = Array.from(e.target.files);
		setUploadDocument(f);
	};

	const getCategoryList = async () => {
		let result = await fundRaiseCatListService();

		if (result.data.status === 200) {
			let cat = result.data.data.category.map((res, i) => {
				return {
					value: res.id,
					//label: res?.name?.en,
					label: res.name?.[lang],
				};
			});
			setCategory(cat);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
		}
		window.scrollTo(0, 0);
	};
	const isBase64Image = (str) => {
		const base64Regex = /^data:image\/([a-zA-Z]*);base64,([^\s]*)$/;

		return base64Regex.test(str);
	};

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('fund_raise.fund_raise') },
					{ link: '#', name: t('admin_user.edit') },
				]}
				title={t('fund_raise.fund_raise')}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}
			<div className="admin-white-box">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row g-2">
						<div className="col-12">
							<label className="admin-lbl">{t('fund_raise.campaign_title_en')}</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control admim-input-style"
									placeholder={t('fund_raise.campaign_title_en')}
									{...register('campaign_title', {
										required: t('fund_raise.campaign_title_en_is_required'),
									})}
								/>
								{errors.campaign_title && <InputErrorMsg error={errors.campaign_title?.message} />}
							</div>
						</div>
						<div className="col-12">
							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-6 col-12">
									<label className="admin-lbl">{t('fund_raise.campaign_description_en')}</label>
									<div className="admim-input-wrap">
										<textarea
											className="form-control resize-none"
											placeholder={t('fund_raise.campaign_description_en')}
											rows="5"
											{...register('Campaign_short_description', {
												required: t('fund_raise.campaign_description_en_is_required'),
											})}
										></textarea>
										{errors.Campaign_short_description && <InputErrorMsg error={errors.Campaign_short_description?.message} />}
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-6 col-12">
									<label className="admin-lbl">{t('admin_user_type.project_startup_description')}</label>
									<div className="admim-input-wrap">
										<textarea
											className="form-control resize-none"
											placeholder={t('admin_user_type.project_startup_description')}
											rows="5"
											{...register('Campaign_description', {
												required: t('admin_user_type.project_startup_description_is_required'),
											})}
										></textarea>
										{errors.Campaign_description && <InputErrorMsg error={errors.Campaign_description?.message} />}
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('admin_dashboard_menu.category')}</label>
							<div className="admim-select-wrap">
								<Controller
									name="category"
									control={control}
									render={({ field }) => <Select {...field} options={category} />}
									rules={{
										required: t('admin_dashboard_menu.category_is_required'),
									}}
								/>
								{errors.category && <InputErrorMsg error={errors.category?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('fund_raise.campaign_end_date')}</label>
							<div className="admim-input-wrap">
								<input
									type="date"
									className="form-control admim-input-style"
									placeholder={t('fund_raise.campaign_end_date')}
									{...register('campaign_end_date', {
										required: t('fund_raise.campaign_end_date_is_required'),
									})}
								/>
								{errors.campaign_end_date && <InputErrorMsg error={errors.campaign_end_date?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-12">
							<label className="admin-lbl">{t('admin_user.region')}</label>
							<div className="admim-select-wrap">
								<select
									className="form-control selectArrowBlk admim-select-style"
									{...register('region', {
										required: t('admin_dashboard_menu.region_is_required'),
									})}
								>
									<option value="">{`${t('funding_details.select')}`}</option>
									{regionList?.map((value, index) => {
										return (
											<option key={index} value={value.id}>
												{value.name}
											</option>
										);
									})}
								</select>
								{errors.region && <InputErrorMsg error={errors.region?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('admin_user.city')}</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control admim-input-style"
									placeholder={t('admin_user.city')}
									{...register('city', {
										required: t('fund_raise.city_is_required'),
									})}
								/>
								{errors.city && <InputErrorMsg error={errors.city?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('admin_user.address')}</label>
							<div className="admim-input-wrap">
								<input type="text" className="form-control admim-input-style" placeholder={t('admin_user.address')} {...register('address')} />
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('fund_raise.budget_needed_mru')}</label>
							<div className="admim-input-wrap">
								<input
									type="number"
									className="form-control admim-input-style"
									placeholder={t('fund_raise.budget_needed')}
									{...register('budget_needed', {
										required: t('fund_raise.budget_needed_is_required'),
									})}
								/>
								{errors.budget_needed && <InputErrorMsg error={errors.budget_needed?.message} />}
							</div>
						</div>
						<div className="col-lg-8 col-md-8 col-sm-6 col-12">
							<div className="overfolow-hid">
								<label className="admin-lbl">{t('fund_raise.upload_images')}</label>
								<div className="row g-2">
									<div className="col-auto image-upload-wrap">
										<div className="admim-input-wrap">
											<input type="file" id="image_upload" className="form-control" multiple onChange={handleFile} />
											<label htmlFor="image_upload" className="image-upload-lbl">
												<i className="fas fa-upload"></i>
												{t('fund_raise.upload_images')}
												{uploadImage.length > 0 && (
													<span className="doc-count">
														{uploadImage.length} {t('fund_raise.images_selected')}
													</span>
												)}
											</label>
										</div>
									</div>
									<div className="col d-flex file-scroll">
										{uploadImage.map((res, i) => {
											return (
												<>
													<div className="fund-img-wrap">
														{isBase64Image(res) ? (
															<img className="img-block" src={res} alt="" />
														) : (
															<img className="img-block" src={res?.media_path + '/' + res?.media_name} alt="" />
														)}

														<button type="button" className="image-del" onClick={() => deleteFile(i)}>
															-
														</button>
													</div>
												</>
											);
										})}
									</div>
								</div>
							</div>
						</div>

						<div className="col-lg-4 col-md-4 col-sm-12 col-12">
							<label className="admin-lbl">{t('fund_raise.upload_documents')}</label>
							<div className="row g-2">
								<div className="col-auto image-upload-wrap">
									<div className="admim-input-wrap">
										<input type="file" id="document_upload" className="form-control" multiple onChange={handleDocument} />
										<label htmlFor="document_upload" className="image-upload-lbl">
											<i className="fas fa-upload"></i>
											{t('fund_raise.upload_documents')}{' '}
											{uploadDocument.length > 0 && (
												<span className="doc-count">
													{uploadDocument.length} {t('fund_raise.files_selected')}
												</span>
											)}
										</label>
									</div>
								</div>
								<div className="col d-flex file-scroll">
									{fundRaiseDetails?.get_documents?.map((res, i) => {
										return (
											<>
												<div className="doc-download-box">
													<div class="download-list-box">
														<a href={res?.media_path + '/' + res?.media_name} download>
															<i class="fas fa-file-download"></i>
														</a>
													</div>
												</div>
											</>
										);
									})}
								</div>
							</div>
						</div>

						<div className="col-12 cmn-btn-tag-wrap mt-3">
							<button className="cmn-btn-tag">{t('funding_details.submit')}</button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}

export default EditFundRaise;
