import React, { useEffect, useState, useMemo } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import './Funding.scss';
import './Funding.responsive.scss';
import { Link } from 'react-router-dom';
import allActions from 'redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { listCatService, listFundService } from './Funding.service.js';
import { toast } from 'react-toastify';
import DataNotFound from 'components/DataNotFound';
import ReactPaginate from 'react-paginate';
import { EqualHeight, EqualHeightElement } from 'react-equal-height';

function Funding() {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [catList, setCatList] = useState([]);
	const [fundList, setFundList] = useState([]);
	const [fundListMeta, setFundListMeta] = useState([]);
	const [page, setPage] = useState(1);
	const [activeCategory, setActiveCategory] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [searchfund, setSearchFund] = useState('');

	const lang = useSelector((state) => state.lang);

	useEffect(() => {
		dispatch(allActions.loadingAction.showLoading());

		Promise.all([getCatList(), getFundList()]).then((res) => {
			dispatch(allActions.loadingAction.hideLoading());
		});
	}, []);

	const getCatList = async () => {
		let list = await listCatService();
		if (list.data.status === 200) {
			setCatList(list.data.data.category);
		} else {
			toast.error(list.data.error);
		}
	};

	const getFundList = async () => {
		let data = {
			category: activeCategory,
			search_text: searchText,
			lang,
		};
		let list = await listFundService(data, page);

		if (list.data.status === 200) {
			setFundList(list.data.data.records);
			setFundListMeta(list.data.data.meta);
		} else {
			toast.error(list.data.error);
		}
	};
	const handleCategoryCheck = async (e) => {
		const { value, checked } = e.target;
		if (checked) {
			setActiveCategory([...activeCategory, value]);
		} else {
			setActiveCategory(activeCategory.filter((val) => val !== value));
		}
	};

	useMemo(() => {
		dispatch(allActions.loadingAction.showLoading());

		Promise.all([getFundList()]).then((res) => {
			dispatch(allActions.loadingAction.hideLoading());
		});
	}, [activeCategory, searchfund, page]);

	return (
		<>
			<Header />
			<section className="fund-sec no-banner">
				<div className="container-fluid left-right-40">
					<div className="fund-page-wrap d-flex flex-wrap">
						<div className="fund-page-wrap-lft">
							<div className="catagory-box">
								<h4>{t('funding_page.categories')}</h4>
								<ul className="filter-chk">
									{catList.map((val, i) => (
										<li key={i} className="checkbox">
											<input type="checkbox" id={`chk${i}`} value={val?.id} onChange={handleCategoryCheck} />
											<label htmlFor={`chk${i}`}>{val?.name?.[lang]}</label>
										</li>
									))}
								</ul>
							</div>
						</div>
						<div className="fund-page-wrap-rgt">
							<div className="search-wrap">
								<input
									type="text"
									className="form-control search-input-style"
									placeholder={t('funding_page.search_for_fundraisers')}
									onChange={(e) => {
										setSearchText(e.target.value);
									}}
								/>
								<span className="src-icon-wrap">
									<button
										type="button"
										className="src-icon-btn"
										onClick={() => {
											setSearchFund(searchText);
										}}
									>
										<i className="fas fa-search" />
									</button>
								</span>
							</div>
							<EqualHeight>
								<div className="row mt-3">
									{fundList.length > 0 ? (
										fundList.map((val, i) => (
											<div className="col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" key={i}>
												<div className="found-raiser-box">
													<Link to={`/fund-details/${btoa(val.id)}`} className="found-raiser-info">
														<span className="found-raiser-img">
															<img className="img-block" src={`${val?.get_images?.[0]?.media_path}/${val?.get_images?.[0]?.media_name}`} alt="fund" />
														</span>
														<div className="found-raiser-desc">
															<EqualHeightElement name="Title">
																<p>{val?.campaign_title?.[lang]}</p>
															</EqualHeightElement>
															<EqualHeightElement name="Desc">
																<span className="post-fund-raiser">
																	{t('funding_page.raised')} {t('funding_page.by')} {val?.get_user?.first_name} {val?.get_user?.last_name}
																</span>
															</EqualHeightElement>
															<EqualHeightElement name="Mru">
																<h4>
																	MRU {val?.total_fund ? val?.total_fund?.toLocaleString() : 0} <span>{t('funding_page.raised')}</span> / MRU{' '}
																	{val?.budget_needed?.toLocaleString()} <span>goal</span>
																</h4>
															</EqualHeightElement>
															<div className="price-donate">
																<span className="donate-bar" style={{ width: parseInt((val?.total_fund / val?.budget_needed) * 100) + '%' }}>
																	<div class="price-donate-top">
																		<span class="count-text">
																			{parseInt(((val?.total_fund ? val?.total_fund : 0) / val?.budget_needed) * 100)}
																		</span>
																		%
																	</div>
																</span>
															</div>
															<span className="post-fund-raiser">
																{t('funding_page.last_donation')} {val?.last_donation ? val?.last_donation : 'N/A'}
															</span>
															<EqualHeightElement name="DD">
																<ul className="d-flex justify-content-between align-items-center">
																	<li>
																		{val?.days_left > 0 ? (
																			<>
																				{t('funding_page.days_left')} <strong>{val?.days_left}</strong>
																			</>
																		) : (
																			<>
																				<strong className="expired">{t('funding_page.donation_date_expired')}</strong>
																			</>
																		)}
																	</li>
																	<li>
																		{t('funding_page.supporters')} <strong>{val?.get_success_donations?.length}</strong>
																	</li>
																</ul>
															</EqualHeightElement>
														</div>
													</Link>
												</div>
											</div>
										))
									) : (
										<DataNotFound text={'No Data Found'} />
									)}
									{fundList.length > 0 && (
										<ReactPaginate
											previousLabel={t('previos')}
											nextLabel={t('next')}
											breakLabel={'...'}
											pageCount={fundListMeta?.totalPages}
											marginPagesDisplayed={2}
											pageRangeDisplayed={3}
											onPageChange={(page) => setPage(page.selected + 1)}
											containerClassName={'pagination justify-content-center custom-paginate'}
											pageClassName={'page-item'}
											pageLinkClassName={'page-link'}
											previousClassName={'page-item'}
											previousLinkClassName={'page-link'}
											nextClassName={'page-item'}
											nextLinkClassName={'page-link'}
											breakClassName={'page-item'}
											breakLinkClassName={'page-link'}
											activeClassName={'active'}
										/>
									)}
								</div>
							</EqualHeight>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
}

export default Funding;
